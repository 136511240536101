.menu-cat {
    padding: 8px 15px;
    /*font-size: 0.75rem;*/
    font-weight: 600;
    width: $widthColumnLeft;
    
    a {
        color: $colorFooter;
        
        &:hover {
            text-decoration: underline;
        }
    }
    
    .menu {
        >li {
            >a {
                text-transform: uppercase;
                font-size: 0.9rem;
                padding: 0.785rem 0 0.5rem 0;//0.4rem 0 0.5rem 0;
                line-height: 1.2;
            }
            >ul {
                margin: -0.4rem 0 0 0;
                list-style: none;
                >li>a {
                    font-size: 0.8em;
                    padding: 0rem 1rem 0.5rem 1rem;
                    line-height: 1.1;
                }
            }
        
            >div {
                display: none;
                background-color: #ffffff;
                border: 2px solid #CECECE;
                border-radius: 3px;
                margin-left: 100%;
                padding: 0.5rem 5px;
                position: absolute;
                top: 0;
                z-index: 199;
                /*width: 714px;*/

                .sub-menu-header {
                    border-bottom: 1px solid $colorBorder;
                    margin: 0 1.25rem 0.5rem 1.4rem;
                    font-size: 0.8em;
                    padding-bottom: 0.3rem;
                }
                .sub-menu-footer {
                    border-top: 1px solid $colorBorder;
                    margin: 0.3rem 1.25rem 0.15rem;
                    padding-top: 0.45rem;
                    text-align: right;

                    .button {
                        display: inline-block;
                        padding: 0.25rem 1rem 0.2rem;
                    }
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    column-count: 2;
                    >li {
                        width: 230px;
                        /*display: flex;*/

                        a {
                            /*display: inline-block;*/
                            padding: 0.25rem 0.5rem 0.25rem 0.25rem;
                            font-size: 0.9em;
                            font-weight: normal;
                        }
/*                        a:hover {
                            text-decoration: underline;
                        }*/
                        label {
                            font-size: 0.9em;
                        }
    /*                    input {
                            display: inline-block;;
                        }*/
                    }
                }
            }
        }
        .icon-submenu {
            float: right;
        }
        >li.has-sub>a {
            /*white-space: nowrap;*/
        }
        >li.has-sub:hover >div {
            display: block;
        }
        &#navleft-info.menu {
            >li >ul >li>a {
                position: relative;
                padding-left: 30px;
                
                &:before {
                    content: '+';
                    position: absolute;
                    margin-left: -11px;
                    font-size: 0.9rem;
                }
            }
            >li>div ul {
                column-count: 1;
            }
        }
        &#navleft-profile.menu {
            >li {
                margin-top: 1rem;
                
                &:first-child {
                    margin-top: 0.5rem;
                }
            }
            >li>ul {
                margin-top: 0.5rem;
                
                >li>a {
                    padding-bottom: 0.7rem;
                }
            }
            a.active {
                color: $colorActiveSupport;
            }
        }

    }
}

.menu .active > a {
    background-color: transparent;
    color: $colorContrast;
}

.menu-cat-dropdown.dropdown-pane {
    width: $widthColumnLeft;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    padding: 0; 
        
    a:focus {
        outline: none;
    }
    .menu-cat {
        padding-top: 5px;//0;
        border-top: 1px solid $colorBorder;
        width: $widthColumnLeft - 2px;
    }
    .l-block.menu-cat-support {
        padding-top: 12px;
        /*margin-bottom: 0;*/
        margin-bottom: 0.1rem;
        padding-bottom: 0.7rem;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.3);
        border-radius: 0;
    }
}

@media screen and #{breakpoint(xlarge)} {
    .menu-cat {
        padding: 12px 28px 8px;//15px 28px 8px;
        /*font-size: 0.86rem;*/
        width: $widthColumnLeft1200;
        
        .menu {
            >li>a {
                font-size: 0.97rem;
            }
            >li>div {
                ul {
                    column-count: 3;
                }
            }
            /*.icon-submenu {margin-left: 28px;}*/
        }
    }
    .menu-cat-dropdown.dropdown-pane {
        width: $widthColumnLeft1200;
        
        .menu-cat {
            width: $widthColumnLeft1200 - 2px;
        }
        .menu-cat-support.l-block {
            padding-top: 8px;//17px;
            /*margin-bottom: 0.5rem;*/
        }
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .menu-cat {
        width: $widthColumnLeft1600;
    }
    .menu-cat-dropdown.dropdown-pane {
        width: $widthColumnLeft1600;
        .menu-cat {
            width: $widthColumnLeft1600 - 2px;
        }
    }
}