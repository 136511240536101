.header-menu {
    background-color: $colorPrimary;
    
    .menu {
        a {
            /*box-sizing: border-box;*/
            color: $colorMenu;
            text-transform: uppercase;
            text-align: center;
            padding: $mainMenuItemPaddingTop 1rem $mainMenuItemPaddingBottom;
            border-bottom: 3px solid transparent;
            font-weight: 800;
        }
        a:hover, a.active {
            background-color: $colorPrimarySupport;
            border-bottom: 3px solid $colorActive;
        }
        
        li.mm-0 a span {
            position: relative;
            
/*            &:before {
                content: '';
                position:absolute;
                //z-index:-1;
                top: 3px;
                left: -52px;
                width:22px;
                height:18px;
                background: url(/img/icons.png) no-repeat -10px -882px;
            }*/
            &:after {
                content: '';
                position:absolute;
                //z-index:-1;
                top: 3px;
                right: -40px;//-47px;
                width: 16px;
                height: 18px;
                background: url(/img/icons.png) no-repeat -45px -885px;
            }
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .header-menu .menu {
        li.mm-1 a {
            /*margin-left: $marginBlockDefault;*/
            /*padding: $mainMenuItemPaddingTop 20px $mainMenuItemPaddingBottom;*/
            padding: $mainMenuItemPaddingTop get-list-value($headerWorkAreaMargin, medium) $mainMenuItemPaddingBottom;
        }
        li.mm-2 a {
            padding: $mainMenuItemPaddingTop 15px $mainMenuItemPaddingBottom;
        }
        li.mm-3 a {
            padding: $mainMenuItemPaddingTop 25px $mainMenuItemPaddingBottom;
        }
        li.mm-4 a {
            padding: $mainMenuItemPaddingTop 25px $mainMenuItemPaddingBottom;
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .header-menu .menu {
        li.mm-0 a span {
/*            &:before {
                left: -60px;
            }*/
/*            &:after {
                right: -65px;
            }*/
        }
        li.mm-1 a {
            /*margin-left: $marginBlock1200;*/
            /*padding: $mainMenuItemPaddingTop 26px $mainMenuItemPaddingBottom;*/
            padding: $mainMenuItemPaddingTop get-list-value($headerWorkAreaMargin, xlarge) $mainMenuItemPaddingBottom;
        }
        li.mm-2 a {
            padding: $mainMenuItemPaddingTop 15px $mainMenuItemPaddingBottom;
        }
        li.mm-3 a {
            padding: $mainMenuItemPaddingTop 45px $mainMenuItemPaddingBottom;
/*            padding: $mainMenuItemPaddingTop 38px $mainMenuItemPaddingBottom;*/
        }
        li.mm-4 a {
            padding: $mainMenuItemPaddingTop 45px $mainMenuItemPaddingBottom;
/*            padding: $mainMenuItemPaddingTop 38px $mainMenuItemPaddingBottom;*/
        }
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .header-menu .menu {
        li.mm-0 a span {
/*            &:before {
                left: -68px;
            }*/
/*            &:after {
                right: -51px;
            }*/
        }
        li.mm-1 a {
            /*padding: $mainMenuItemPaddingTop 66px $mainMenuItemPaddingBottom;*/
            padding: $mainMenuItemPaddingTop get-list-value($headerWorkAreaMargin, xxxlarge) $mainMenuItemPaddingBottom;
        }
        
        // remove when consulting turn on
        li.mm-3 a {
            padding: $mainMenuItemPaddingTop 66px $mainMenuItemPaddingBottom;
        }
        li.mm-4 a {
            padding: $mainMenuItemPaddingTop 66px $mainMenuItemPaddingBottom;
        }
    }
}
