.page-comments {
    .rem-big {
        margin: 1.2rem 0;
    }
}
.mobile.page-comments {
    .rem-big {
        margin: 0.5rem 0;
        font-size: 0.8rem;
    }
    .pager-top {
        margin: 0.75rem 0 0rem;
    }
    .pager-labels {
        margin-top: 5px;
    }
}