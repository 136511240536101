/*.lot-form {
    .field {
        margin-right: 5px;
    }
}*/
.page-lot-view {
    .block-main {
        position: relative;
    }
    .manage-links {   
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        
        a {
            float: left;
            font-size: .8rem;
        }
    }
}
.mobile.page-lot-view {
    article {
        padding-top: 1.7rem;
    }
}
.lot-form {
    .product-img {
        margin: 0;
        padding-right: 0;
        
        .form-group {
            position: initial;
            margin-right: 0;
            padding-right: 0;
        }
        label {
            position: relative;
            border: 1px solid $colorBorder;
            width: 310px;
            height: 310px;
            cursor: pointer;

            input[type="file"] {
                display: none;
            }
            .rem {
                text-shadow: 1px 1px 1px #fff;
                display: block;
                padding: 0.5rem 1rem;
                background-color: rgba(255,255,255,0.65);
                color: #000;
                text-align: center;
            }
        
            .help-block {
                position: absolute;
                bottom: 0;
                padding: 0.5rem;
                background-color: rgba(255,255,255,0.65);
            }
        }
    }
    .lot-add-image {
        cursor: pointer;
        display: inline-block;
        position: relative;
        
        span {
            color: $colorPrimary;
            text-decoration: underline;
        }
        input[type="file"] {
            display: none;
        }
        .has-error .help-block {
            text-decoration: none;
        }
    }
    .product-gal-img {
        display: block;
        width: 100px;
        height: 100px;
        border: 1px solid $colorBorder;
        border-radius: 3px;
        margin-left: 5px;
        margin-bottom: 5px;
        
        &:nth-child(3n+1) {
            margin-left: 0;
        }
    }
    .product-gal-img.gal-img-new {
        border: 2px solid $colorActive;
    }
    .product-gal-img-del {
        position: relative;
        display:block;
        width: 100%;
        height: 100%;
        background: transparent;
        
        &:hover {
            background: rgba(0,0,0,0.7);
            span.icon {
                display: block;
            }
        }
        
        span.icon {
            display: none;
            position: absolute;
            width: 20px; height: 20px;
            top: 40px; left: 40px;
            border-radius: 10px;
        }
    }
    
    #lot-add-images {
        display: flex;
        flex-wrap: wrap;
    }
}
.manage-links {
    a {
        font-size: 0.9rem;
        padding-left: 0.5rem;
    }
    a:first-child {
        padding-left: 0;
    }
}
.lot-view {
    margin-bottom: 1.5rem;
    
    .product-passport {
        position: relative;
    }
    .product-img {
        border: 1px solid $colorBorder;
        margin-top: 0;
    }
    .lot-description {
        margin-top: 0.5rem;
    }
    .lot-author {
        margin-top: 1.5rem;
    }
    .lot-price-history {
        padding-left: 4.3rem;
        margin-bottom: 1rem;
        margin-top: -0.5rem;
        
        a {
            font-size: 0.813rem;
        }
    }
}
.lot-section {
/*        position: absolute;
    right: 0;
    top: 0;*/
    width: 84px;
    font-size: 0.86rem;
    color: $colorContrast;
    background-color: $colorBorder;
    padding: 0.2rem 0.2rem 0.25rem;
    border-radius: 2px;
    text-align: center;
}
.obj-box-wrapper-buy .lot-section {
    padding: 0.1rem 0.25rem;
    margin-bottom: .1rem;
}
h1 .lot-section {
    line-height: 1rem;
    vertical-align: middle;
    margin-right: 0.2rem;
}
.lot-section-wrapper-mob {
    position: absolute;
    top: 0;
    right: 0;
    
    .lot-section {
        display: inline-block;
        padding: 0.1rem 0.25rem;
        margin-bottom: .1rem;
    }
}

.lot-date .o-label, .lot-date .g-label {
    padding: 0.3rem 1rem 0.3rem;
}
.lot-contacts {
    font-size: 0.9rem;
    background-color: $colorEvenTableRow;
    padding: 1rem;
     border-radius: 2px 0 2px 2px;
}
.lot-contacts-info {
    font-size: .9rem;
    background-color: $colorEvenTableRow;
    padding: 0.2rem 0.5rem;
    border-radius: 2px 2px 0 0;
}
.lot-menu {
    li {
        border: 1px solid transparent;
        border-radius: 3px;
        
        &.active {
            border: 1px solid $colorPrimary;
            /*margin: 0 -1rem;*/
            padding: 0 1rem;
        }
/*        &:hover, &:focus {
            border: 1px solid $colorActive;
        }*/
        &.cat-my.active {
            padding: 0 0.5rem;
            margin: 0 -1rem;
        }
    }
    a {
        display: flex;
        align-items: center;

        .icon-cat {
            min-width: 45px;
            height: 45px;
            margin-right: 0.7rem;
        }
        >span {
            display: inline-block;
        }
    }
    .cat-blade .icon-cat {
        background-position: -11px -170px;
    }
    .cat-rubber .icon-cat {
        background-position: -76px -113px;
    }
    .cat-clothes .icon-cat {
        background-position: -75px -170px;
    }
    .cat-shoes .icon-cat {
        background-position: -10px -229px;
    }
    .cat-racket .icon-cat {
        background-position: -10px -111px;
    }
    .cat-misc .icon-cat {
        background-position: -78px -467px;
    }
    .cat-all .icon-cat {
        background-image: none;
    }
    .cat-all .icon-cat {
        background-image: none;
    }
    .cat-ttm .icon-cat {
        background-position: -3px -6px;
        min-width: 38px;
        max-width: 38px;
        margin-right: 1rem;
    }
    .cat-my .icon-cat {
        /*background-position: -5px -465px;*/
        background-position: -13px -467px;
        transform: rotate(30deg);
    }
    .cat-rules .icon-cat {
        background-position: -13px -528px;
        transform: rotate(46deg);
    }
    .cat-my-groups .icon-cat {
        background-image: none;
        
        &::before{
            display: block;
            content: "+";
            color: $colorPrimary;
            font-size: 3em;
            margin-top: -6px;
            margin-left: 10px;
        }
    }
}
.desktop .lot-menu li.active {
    margin: 0 -1rem;
}
#navleft-profile .add {
    position: relative;
    a {
        padding-left: 1.85em;
        padding-top: 0.3rem;
    }
    &::before {
        position: absolute;
        display: block;
        content: "+";
        color: $colorPrimary;
        font-size: 2em;
        margin-top: -10px;
        margin-left: -3px;
    }
}
#navleft-groups li div {
    display: flex;
    
    a.name {
        /*display: block;*/
        flex: 1 1 auto;
        padding: 0 0 0 0.2rem !important;
        font-weight: normal;
        font-size: 0.9rem;
    }
    a.lot-group-btn {
        flex: 0 1 auto;
        padding: 0;
    }
} 
.menu-cat .menu.lot-menu > li > a {
    padding: 0.3rem 0;
    &:hover {
        text-decoration: none;
        color: $colorActive;
    }
}
.menu-cat .menu > li.cat-ttm > a {
    color: $colorActive;
}

.switcher-group {
    display: flex;
    margin-left: 0;
    
    li {
        display: block;
        flex: 1 0 33%;
        text-align: center;
        
        &.active a  {
            border: 1px solid $colorPrimary;
            color: $colorPrimary;
        }
        &:hover a, &:focus a {
            border: 1px solid $colorActive;
            color: $colorActive;
            text-decoration: none;
        } 
    }
    &.seller-filter li {
        flex: 0 0 auto;
        
        a {
            padding: 0.1rem 1rem;
        }
    }
    
    a {
        display: block;
        border: 1px solid $colorBorder;
        color: #D5D5D5;/*$colorBorder;*/
        font-size: 0.8rem;
        text-transform: uppercase;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        text-decoration: none;       
    }
}
#switcher-condition.switcher-group {
    li {
        flex: 1 0 auto;
    }
    &.seller-filter li a {
        padding: 0.1rem 0.3rem;
    }
}

#preview-new-lots {
    max-height: 250px;
    overflow: scroll;
}

.seller-filters {
    padding-top: 1rem;
}
.seller-tabs {
    position: relative;
    
    .tabs {
        margin-top: .85rem;
    }
    .adm-operations {
        position: absolute;
        right: 12px;
        top: -54px;
        
        a {
            color: red;
        }
    }
}
a.lot-last-info {
    font-size: 0.7rem;
    font-weight: normal;
    color: $colorRemark;
    text-decoration: none;
}
.last-sh-counts {
    display: flex;
    font-size: 0.813rem;
    
    span {
        flex: 1 0 33%;
        text-align: center;
    }
}


td.edited {
    padding: 0.5rem 0;
    background-color: #66ac4a;
}
td.edited input {
    margin-bottom: 0rem;
}
.package-list td {
    font-size: 0.9rem;
}

@media screen and #{breakpoint(medium)} {
    .page-lot-view .manage-links{
        top: 0.60em;
        right: 1.5rem;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .page-lot-view .manage-links {
        top: 0.65em;
        right: $paddingWorkArea1200;
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .page-lot-view .manage-links {
        top: 1.5em;
        right: $paddingWorkArea1600;
    }
}