.button {
    border-radius: 2px;
    font-weight: 600;
    padding: 0.85em 1em 0.7em;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0;
    
    .icon {
        margin-right: 7px;
    }
    &:focus {
        outline: none;
    }
}
.text-button {
    .icon {
        margin-right: 7px;
    }
}
.btn-standard {
    background-color: $colorPrimarySupport;
    border-bottom: 3px solid $colorPrimary;
}
.btn-standard:hover, .btn-standard:focus {
    background-color: $colorPrimary;
    border-bottom: 3px solid $colorPrimary;
}

.btn-action {
    color: $colorFooter;
    background-color: $colorActive;
    border-bottom: 3px solid $colorActiveSupport;
    font-weight: 800;
}
.btn-action:hover, .btn-action:focus {
    color: $colorFooter;
    background-color: $colorActiveSupport;
}

.btn-secondary{
    font-size: 0.7rem;
    text-transform: uppercase;
    padding-top: 0.4rem;
    padding-bottom: 0.35rem;
    color: $colorPrimary;
    background-color: transparent;
    border: 1px solid $colorPrimary;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary-dis:hover, .btn-secondary-dis:focus {
    color: $colorActiveSupport;
    background-color: transparent;
    border: 1px solid $colorActiveSupport;
}
.btn-secondary-dis{
    font-size: 0.7rem;
    text-transform: uppercase;
    padding-top: 0.4rem;
    padding-bottom: 0.35rem;
    color: $colorRemark;
    background-color: transparent;
    border: 1px solid $colorBorder;
}
.btn-reset{
    font-size: 0.7rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    color: $colorActiveSupport;
    background-color: transparent;
    border: 1px solid $colorActiveSupport;
}
.btn-reset:hover, .btn-reset:focus {
    color: $colorFooter;
    background-color: $colorActiveSupport;
    border: 1px solid $colorActiveSupport;
}
.btn-cancel-edit {
    font-size: .9rem;
    margin-right: 1rem;
}

.btn-w-icon {
    span {
        display: inline-block;
        vertical-align: middle;
    }
}

.btn-cancel {
    color: $colorContrast;
    background-color: $colorBorder;
    border-bottom: 3px solid $colorBorder;
}
.btn-disable {
    color: $colorRemark;
    background-color: $colorBorder;
    border-bottom: 3px solid $colorBorder;
}
.btn-disable:hover, .btn-disable:focus {
    color: $colorRemark;
    background-color: $colorBorder;
    border-bottom: 3px solid $colorBorder;
}
/*.btn-cancel:hover, .btn-cancel:focus {
    background-color: $colorBorder;
    border-bottom: 3px solid $colorBorder;
}*/
.btn-b {
    font-weight: 800;
}
.btn-sm {
    font-size: 0.9rem;
    padding: 0.25rem 1rem 0.2rem;
}