.menu-home-mobile-group, .menu-home-mobile {
    list-style-type: none;
    margin: 0;
    
    li {
        position: relative;
        border-bottom: 1px solid $colorBg;
        height: 78px;
        
        &:nth-child(odd) {
            border-right: 1px solid $colorBg;
        }
    }
    a {
        display: block;
        height: 78px;
        text-decoration: none;
        color: $colorContrast;
        text-transform: uppercase;
        font-size: 0.813rem;
        font-weight: 600;
        
        span {
            display: inline-block;
            vertical-align: middle;
            margin: 0.5rem 0;
            line-height: 1.1;
            
            &.cat-name:hover, &.sub-cat:hover {
                text-decoration: underline;
            }
        }
    }
}
.menu-home-mobile-group {
/*    a span {
        display: inline-block;
        vertical-align: middle;
        margin: 0.5rem 0;
        
        &.cat-name:hover {
            text-decoration: underline;
        }
    }*/
}
.menu-home-mobile {
/*    li.m-s-rubber, li.m-s-clothes {
        a {
            display: block;
            height: 50%;
        }
    }*/
    .sub-cat {
        text-transform: none;
        font-size: 0.7rem;
    }
    a.cat-sub-name {
        position: absolute;
        bottom: 0;
        left: 60px;
        height: auto;
    }
    .icon-cat {
        width: 55px; height: 60px;
    }
    .cat-name {
        width: 90px;
    }
    .cat-glue a span.cat-name {
        margin: 0.2rem 0;
    }
/*    .cat-rubber {
        .cat-name {
            vertical-align: top;
            margin-top: 0.7rem;
        }
    }
    .cat-shoes {
        .cat-name {
            vertical-align: top;
            margin-top: 1rem;
        }
        a.cat-sub-name {
            bottom: 5px;
        }
    }*/
}
.page-getLastArticles {
    .menu-home-mobile {
        li {
            height: 45px;
        }
        a {
            height: 45px;
            padding-left: 1rem;
            
            span.icon {
                margin-right: 0.3rem;
            }
        }
    }
    .menu-home-mobile-wrapper.block {
        padding: 0;
        margin-bottom: 0.3rem;
    }
}

.icon-cat {
    display: inline-block;
    background-image: url(/img/icons-categories.png);
    background-repeat: no-repeat;
}

.icon-cat-sales {background-position: -5px 0; width: 70px; height: 60px; margin: 0.5rem 5px 0.5rem 15px !important;}
.icon-cat-news {background-position: -6px -52px; width: 70px; height: 60px;}
.icon-cat-hits {background-position: -75px 0px; width: 70px; height: 60px; margin-left: 13px !important;}
.icon-cat-brands {background-position: -69px -52px; width: 70px; height: 60px; margin: 0.5rem 10px 0.5rem 2px !important;}

.cat-racket .icon-cat {background-position: -5px -105px;}
.cat-rubber .icon-cat {background-position: -72px -105px;}
.cat-blade .icon-cat {background-position: -6px -163px;}
.cat-clothes .icon-cat {background-position: -70px -163px;}
.cat-shoes .icon-cat {background-position: -6px -221px;}
.cat-glue .icon-cat {background-position: -77px -216px;}
.cat-bag .icon-cat {background-position: -6px -279px;}
.cat-bat .icon-cat {background-position: -72px -279px;}
.cat-net .icon-cat {background-position: -6px -344px;}
.cat-table .icon-cat {background-position: -70px -342px;}
.cat-robot .icon-cat {background-position: -6px -404px;}
.cat-count .icon-cat {background-position: -70px -403px;}
.cat-ball .icon-cat {background-position: -4px -574px;} /*-5px -465px*/
.cat-misc .icon-cat {background-position: -71px -464px;}
.cat-sponge .icon-cat {background-position: -6px -519px;}
.cat-socks .icon-cat {background-position: -75px -519px;}