.tabs-title {
/*    border-bottom: 1px solid $colorBorder;*/
    
    > a {
        position: relative;
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        border-bottom: 1px solid $colorBorder;
        
        .obj-box-lbl {
            position: absolute;
            display: flex;
            top: 0;
            right: -11px;
            
            .obj-box-lbl-text {
                font-size: .7rem;
                font-weight: 600;
                line-height: 15px;
                height: 18px;
                width: 32px;
                background-color: $colorActive;
                border: 1px solid #fff;
                border-radius: 1px;
                color: #fff;
                text-align: center;
                text-transform: lowercase;
            }
        }
    }
    > a:hover, > a:focus, > a[aria-selected='true'] {
        color: $colorPrimary;
        padding-bottom: 7px;
        border-bottom: 2px solid $colorPrimary;
        outline: none;
    }
}
.tabs-content {
    background-color: #fff;
}
.tabs-content .dropdown-target {
    padding-top: 0.5rem;
    
    p {
        margin-bottom: 0.5rem;
        font-size: 0.9em;
    }
}

.mobile-tabs {
    ul {
        display: table;
        width: 100%;
    }
    li {
        display: table-cell;
        text-align: center;
    }
    
    &.mobile-tabs-3item li {
        width: 33.3%;
    }
    &.mobile-tabs-2item li {
        width: 50%;
    }
    
    &.tabs-title {
        float: none;
    }
    .tabs-panel {
        padding-top: 0.5rem;
        
        .sub-caption {
            font-size: 0.8rem;
            display: block;
            padding-left: 0.5rem;
        }
    }
    .product-list {
        margin-top: 0.3rem;
    }
}