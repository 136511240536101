.page-getForm .block-header,
.page-viewCart .block-header {
    margin-bottom: 1.4rem;
    margin-top: 0.3rem;
}


.standard-page .block {
    font-size: 0.875rem;
}

.page-delivery-payment {
    .bn-link span.icon-dlv {
        margin-right: 0;
    }
    .bn-link span.name {
        display: block;
    }
    .bl-pay-methods {
        margin: 1rem 0 1.5rem;
    }
    p.highlight {
        margin-bottom: 1.5rem;
    }
    .msg {
        margin-bottom: 1rem;
    }
    
    ol.ol-bold-standard {
        .media-object {
            margin-top: 0.5rem;
            margin-left: 1.1rem;
        }
        .icon {
            margin-top: 3px;
        }
    }
    .section-icon {
        margin-right: 1rem;
    }
    .cell {
        text-align: center;
        margin-bottom: 0.9375rem;
    }
    .icon-returns {
        margin-bottom: 8rem;
    }
}

.brand-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px 0 -15px;
    
    .obj-brand-caption {
        display: flex;
        align-items: flex-end;
        height: 2rem;
        line-height: 1.1;
        margin-bottom: 0.5rem;
        
        a {
            display: block;
            width: 100%;
            font-size: 0.9rem;
            font-weight: 600;
            color: $colorContrast;
        }
    }
    .obj-brand-content {
        font-size: 0.813rem;
    }
    .obj-box {
        width: 121px !important;
        margin: 25px 16px;
        
        >div {
            text-align: center;
        }
    }
}
.mobile { 
    .brand-list {
        justify-content: center;
    }
}

.orgs-map {
    display: none;
    width: 100%; height: 350px;
}
.order-history-header {
    margin-top: 1.5rem;
    &::first-child {
        margin-top: 0;
    }
    
    >span {
        font-size: 1.1rem;
        font-weight: bold;
    }
}
.order-history-body {
    margin-top: 0.5rem;
}

.sale-block {
    img {
        float: left; max-width: 50%; margin: 0.3rem 1rem 0.5rem 0;
    }
}

@media screen and #{breakpoint(medium)} {
/*    .page-getForm .breadcrumbs,
    .page-viewCart .breadcrumbs {
        display: none;
    }*/
    .page-delivery-payment {
        .bn-link span.icon-dlv {
            margin-right: 7px;
        }
        .bn-link span.name {
            display: inline-block;
        }
        .cell {
            text-align: left;
        }
        .icon-returns {
            margin-bottom: 5rem;
        }
    }
}

@media screen and #{breakpoint(large)} {
/*    .media-object-pseudo-content {
        padding-left: 6.55rem;
    }*/
    .page-delivery-payment {
        .cell {
            margin-bottom: 0;
        }
        .icon-returns {
            margin-bottom: 3.5rem;
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .page-getForm .breadcrumbs,
    .page-viewCart .breadcrumbs {
        display: block;
    }
    .page-getForm .block-header,
    .page-viewCart .block-header {
        margin-bottom: 0.8rem;
        margin-top: 1.8rem;
    }
    
    .brand-list {
        margin: 0 -15px 0 -15px; 
        .obj-box {
            margin: 25px 16px;
        }
    }
}

@media screen and #{breakpoint(xxxlarge)} {
    .brand-list {
        margin: 0 -22px 0 -22px;
        .obj-box {
            margin: 25px 22px;
        }
    }
}