/*https://kyusuf.com/post/completely-css-custom-checkbox-radio-buttons-and-select-boxes*/
/*.control-group {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  text-align: left;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  padding: 30px;
  width: 200px;
  height: 210px;
  margin: 10px;
}*/
.control {
  display: inline-block;
  position: relative;
  padding-left: 18px;
  /*margin-bottom: 15px;*/
  cursor: pointer;
  /*font-size: 18px;*/
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  /*background: #e6e6e6;*/
  border-radius: 2px;
  border: 1px solid #EBEBEB;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  /*background: #ccc;*/
  border: 1px solid $colorFooter;
}
.control input:checked ~ .control__indicator {
  background: $colorFooter;
  border: 1px solid $colorFooter;
}
.control:hover input:not([disabled]):checked ~ .control__indicator{
  background: $colorPrimary;
  border: 1px solid $colorPrimary;    
}
.control input:checked:focus ~ .control__indicator {
/*    background: $colorPrimary;
  border: 1px solid $colorPrimary; */
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
/*  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  transform: rotate(45deg);*/
    height: 10px;
    left: 5px;
    top: 2px;
    transform: rotate(45deg);
    width: 6px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}
.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select select::-ms-expand {
  display: none;
}
.select select:hover,
.select select:focus {
  color: #000;
  background: #ccc;
}
.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: #000;
}
.select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}