
/*.modal-view-container {
    background: rgba(10,10,10,0.85);
}

.modal-view-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    max-width: 980px;
    max-height: 650px;
}

.modal-view-close {
    position: absolute;
    top: 30px;
    right: 0;
    width: 44px;
    height: 44px;
    background: #F0F0F0;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    font-family: Arial;
    font-size: 14px;
    color: #222;
    text-indent: -99999px;
        transition: all .2s;
}

.modal-view-close:hover {
        background: #e4e4e4;
}

.modal-view-close::before,
.modal-view-close::after {
        content: '';
        position: absolute;
        top: 12px;
        left: 22px;
        width: 1px;
        height: 18px;
        background-color: #222;
}

.modal-view-close:before {
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
}

.modal-view-close:after {
        -webkit-transform: rotate(45deg);
           -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
             -o-transform: rotate(45deg);
                transform: rotate(45deg);
}*/

.fancybox-bg {
    background: #424141 !important;
}
.fancybox-is-open .fancybox-bg {
    opacity: 0.5 !important;
}
.modal-view-container {
    .fancybox-close-small {
        position: absolute;
        top: 6px;
        right: 16px;
        width: 30px;
        height: 30px;
        background: #fff;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 2px;
        cursor: pointer;
        font-family: Arial;
        font-size: 14px;
        color: $colorContrast;
        text-indent: -99999px;
        transition: all .2s;
    }

    .fancybox-close-small::before,
    .fancybox-close-small::after {
        content: '';
        position: absolute;
        top: 7px;
        left: 15px;
        width: 2px;
        height: 18px;
        background-color: $colorContrast;
        border-radius: 0;
    }

    .fancybox-close-small:before {
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }

    .fancybox-close-small:after {
        -webkit-transform: rotate(45deg);
           -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
             -o-transform: rotate(45deg);
                transform: rotate(45deg);            
    }
    .fancybox-close-small:hover:after {
        background: $colorContrast;
    }
    .fancybox-close-small:focus:after {
        outline: 0;
    }
    
    .modal-container {
        padding: 0;
        width: auto;
        border: 1px solid #fff;
        border-radius: 2px;
    }
    .modal-title {
        background-color: $colorPrimary;
        color: #fff;
        text-transform: uppercase;
        font-weight: 800;
        padding: 11px 16px 7px 16px;
    }
    .modal-body {
        padding: 16px;
        font-size: 0.9rem;
        
        p {
            margin-bottom: 0.5rem;
        }
        &.restrained {
            max-width: 500px;
        }
    }
    .modal-footer {
        padding: 0 16px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.fancybox-gallery-viewer-layout {
    .fancybox-button {
        color: $colorContrast;
    }
    .fancybox-button:hover:not([disabled]) {
        color: $colorActive;
    }
    .fancybox-button[disabled] {
        opacity: 0.3;
    }
}

.mobile {
    .fancybox-show-thumbs .fancybox-thumbs {
        display: none;
        width: 0;
    }
    .fancybox-show-thumbs .fancybox-inner {
        right: 0;
    }
    .fancybox-navigation button {
        width: 40px;
        height: 70px;
        padding: 0px;
        background: rgba(66, 65, 65, 0.5);
        
        &:before {
            top: 0px;
            right: 0;
            width: 40px;
            height: 70px;
            background: transparent; 
        }
        .icon-arr-left {
            background-position: -6px -1152px;
        }
        .icon-arr-right {
            background-position: -32px -1152px;
        }
    }
    .fancybox-toolbar {
        top: 7px;
        right: -1px;
        
        .fancybox-button {
            background: #fff;
            padding: 6px;
        }
    }
}
@media all and #{breakpoint(medium)} {
    .fancybox-gallery-viewer-layout {
        &.fancybox-show-thumbs {
            .fancybox-button--arrow_right {
                right: -3px;//40px;
            }
            .fancybox-button--arrow_left {
                left: 5px;
                padding: 0;
                width: 30px;
            }
        }
/*        .fancybox-image {
            height: 94%;
            margin: 2% 0;
        }*/
        .fancybox-caption {
            padding: 10px 0;
            text-shadow: 1px 1px 3px #fff;
        }
    }
}
@media all and (min-width: 768px) {

    /* Give space around main area */
    .fancybox-gallery-viewer-layout {
        
        .fancybox-outer {
            top: 10vh;
            left: 10vw;
            bottom: 10vh;
            right: 10vw;
            /*box-shadow: 10px 3px 15px rgba(0,0,0,0.3);*/
            overflow: visible;
            background: #fff;
            border-radius: 3px;
            transition: opacity .3s;
        }

        /* Make it to fade-out while closing */
        &.fancybox-is-closing .fancybox-outer {
            opacity: 0;
        }

        /* Set color for background element */
/*        .fancybox-bg {
            background: #f6f6f6;
        }*/

/*        .fancybox-gallery-viewer-layout.fancybox-is-open .fancybox-bg {
            opacity: 1;
        }*/

        /* Move caption area to the right side */
/*        .fancybox-caption-wrap {
            top: 0;
            right: 44px;
            bottom: 0;
            left: auto;
            width: 300px;
            padding: 0;
            background: #FF6666;
            pointer-events: all;  Make text selectable 
        }*/

/*        .fancybox-caption {
            font-size: 17px;
        }*/

        /* Adjust content area and right arrow position */
        /* In this example, distance from the right is caption width (300) + toolbar width (44) */
/*        .fancybox-stage {
            right: 344px;
        }*/

        /* Remove top border from the caption */
/*       .fancybox-caption {
            padding: 30px 20px;
            border: 0;
        }*/

        /* Align buttons at the right side  */
        .fancybox-toolbar {
            top: 0;
            right: 0;
            bottom: 0;
            left: auto;
            width: 44px;
            /*background: #5E4950;*/
        }

        /* Remove background from all buttons */
        .fancybox-button,
        .fancybox-navigation button:before {
            background: transparent;
        }
        .fancybox-navigation button {
            outline: none;
        }

        /* Change arrow color */
/*        .fancybox-navigation button {
            color: #5E4950 !important;
        }*/

        /* Reposition arrows */
/*        .fancybox-button--arrow_left {
            left: -50px;
        }*/

/*        .fancybox-button--arrow_right {
            right: -50px;
        }*/

        &.fancybox-show-thumbs {
/*            .fancybox-button--arrow_right {
                right: 40px;
            }*/
            .fancybox-inner {
                right: 0; 
                left: 140px;//232px;
            }
        }
        
        .fancybox-thumbs {
            left: 15px;
            right: auto;
            top: 15px;
            width: 125px;
        }
        .fancybox-thumbs > ul > li {
            float: none;
            height: 100px;
            max-width: initial;
            
            &::before {
                opacity: 1;
                border: 1px solid $colorBorder;
                border-radius: 2px;
            }
            >img {
                position: relative;
                min-width: auto;
                min-height: auto;
                margin-left: auto !important;
                margin-right: auto !important;
                display: block;
            }
        }
        .fancybox-thumbs > ul > li.fancybox-thumbs-active {
            &::before {
                border: 1px solid $colorActive;
            }
        }
        .fancybox-caption-wrap {
            background: none;
            border-radius: 0 0 4px 0;
        }
        .fancybox-caption {
            color: $colorContrast;
            border-top: 0;
        }
    }
}