.product-list {
    margin-left: -4px;
    margin-right: -4px;
    margin-top: 0.7rem;
    
    .obj-box {
        background-color: #fff;
        border: 1px solid $colorBorder;
        border-radius: 2px;
        height: auto;
        /*margin: 0 10px 20px;*/
        text-align: center;
        width: 216px;
        /*padding-bottom: 10px;*/
        /*overflow: hidden;*/
        position: relative;
        margin: 4px;
        padding: 8px;
        cursor: pointer;
        
        a {
            text-decoration: none;
            color: $colorFooter;
        }
        img {
            max-height: 200px;
        }
        
        &:hover {
            border: 1px solid $colorActiveSupport;
            box-shadow: 1px 2px 1px #CECECE;
            
            .obj-box-expanded-part {
                display: block;
            }
        }
    }
    .obj-box-img {
        position: relative;
        margin-bottom: 1px;
        height: 200px;
        width: 200px;
        text-align: center;
        font-size: 0.9rem;
        
        a {
            display: block;
            height: 100%;
        }
    }
    .obj-box-date {
        position: absolute;
        /*top: -5px;*/
        font-size: 0.7rem;
    } 
    .obj-box-date-text>span {
        padding: 2px 7px;
    }
    .obj-box-status {
        font-size: 0.8rem;
        margin-bottom: 0;
        margin-top: 5px;
        
        &::first-letter {
            text-transform: uppercase;
        }
    }
    .obj-box-title {
        a {
            font-size: 0.9rem;
            
            span {
                display: flex;
                flex-direction: column;
            }
        }
        a:hover {
            text-decoration: underline;
        }
        .brand-caption {
            font-size: 1em;
            /*font-weight: 600;*/
            height: 32px;
            line-height: 0.9;
            justify-content: flex-end;
            margin-bottom: 5px;
        }
        .product-name {
            height: 50px;
            justify-content: flex-start;
            overflow: hidden;
            line-height: 1.3;
            font-weight: bold;
        }
    }
    .obj-box-desc {
        font-size: 0.8em;
        line-height: 1.4;
        p {
            margin-bottom: 0;
            line-height: 1.2;
        }
    }
    .obj-box-author a {
        font-size: 0.8rem;
        text-decoration: underline;
        color: $colorPrimary;
    }
    a.obj-box-review, .obj-box-links a {
        font-size: 0.8em;
        color: $colorPrimary;
        text-decoration: underline;
        
        &:hover {
            text-decoration: none;
        }
        &.btn-buy {
            text-decoration: none;
            color: $colorContrast;
        }
    }
    .obj-box-expanded-part {
        display: none;
        margin-left: -1px;
        padding: 0px 8px 8px;
        position: absolute;
        width: 216px;
        left: 0;
        z-index: 100;
        
        background-color: #fff;
        border-bottom: 1px solid $colorActiveSupport;
        border-left: 1px solid $colorActiveSupport;
        border-radius: 0 0 2px 2px;
        border-right: 1px solid $colorActiveSupport;
        box-shadow: 1px 2px 1px #CECECE;
    }
    .button {
        width: 100%;
        text-transform: uppercase;
        margin: 5px 0;          
    }
    .obj-box-lbl {
        position: absolute;
        right: 0px;
        top: -1px;
        display: flex;
    }
    .obj-box-lbl-text {
        background-color: $colorActive;
        border: 1px solid #fff;
        border-radius: 1px;
        color: #fff;
        font-size: 0.9rem;
        font-weight: 800;
        height: 21px;
        line-height: 18px;
        text-align: center;
        width: 37px;
    }
    .obj-box-lbl-video {
        position: absolute;
        left: -1px;
        bottom: -1px;
    }
    .obj-box-forward {
        display: block;
        top: 50%;
        position: absolute;
        width: 170px;
        text-transform: uppercase;
        font-size: 0.96rem;
        font-weight: 600;
        margin-top: -1.5rem;
        left: 50%;
        margin-left: -85px;
        color: $colorPrimary;
        
        &:hover {
            color: $colorActiveSupport;
        }
    }
    .lot-section {
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}

ul.list-view-list {
    list-style-type: none;
    margin-left: 0;
}
.product-list.list-view-block .grid-x {
    justify-content: center;
}
// переопределяем вид боксов для view=list
.product-list.list-view-list {
    .small-up-2 > .cell, .xlarge-up-4 > .cell, .xxxlarge-up-5 > .cell {
        width: 100%;
    }
    
    .obj-box {
        text-align: left;
        width: 100%;
        margin: 4px 4px 8px 4px;
        
        &:hover {
            box-shadow: none;
        }
    }
    .obj-box-img {
        margin-bottom: 0;
        
        a {
            display: block;
            height: 100%;
        }
    }
/*    .obj-box-date {
        top: 0px;
    }*/
    .obj-box-title {
        .brand-caption {
            height: auto;
        }
        .product-name {
            height: auto;
            padding-top: 3px;
        }
    }
    .obj-box-price {
        line-height: 1;
    }
    .obj-box-status {
        margin-top: 5px;
    }
    .obj-box-desc {
        font-size: 0.9em;
        line-height: 1.4;
        margin-top: 25px;
        margin-bottom: 20px;
    }
    .obj-box-expanded-part {
        display: block;
        margin-left: 0;
        padding: 0;
        position: relative;
        width: auto;
    }
    .obj-box-img {
        float: left;
    }
    .obj-box-wrapper-except-img {
        margin-left: 210px;
        margin-top: 10px;
    }
    .obj-box .btn-buy, .obj-box-review {
        display: none;
    }
    @include price(column-reverse);
    .price-wrapper {
        align-items: flex-start;
    }
    .lot-section {
        display: inline-block;
        width: auto;
        padding: 0.2rem 0.5rem;
    }
}

// переопределяем вид боксов для view=rows
.product-list.list-view-rows {
    margin-left: 0;
    margin-right: 0;
    
    .obj-box {
        text-align: left;
        width: 100%;
        margin: 0;
        display: flex;
        border-radius: 0;
        margin-top: -1px;
        z-index: 9;
        
        &.even {
            background-color: $colorEvenTableRow;
        }
        &:hover {
            box-shadow: none;
            z-index: 10;
        }
    }
    .obj-box-img {
        margin-bottom: 0;
        width: 62px;
        height: 62px;
        margin-right: 10px;
        flex-shrink: 0;
        
        a {
            display: block;
            height: 100%;
        }
        img {
            max-height: 62px;
        }
    }
    .obj-box-date {
        position: initial;
    } 
    .obj-box-title {
        .brand-caption {
            height: auto;
        }
        .product-name {
            height: auto;
            /*padding-top: 3px;*/
        }
    }
    .obj-box-status {
        margin-top: 3px;
        line-height: 1;
        margin-bottom: 0;
    }
    .obj-box-wrapper-except-img {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .obj-box-wrapper-info {
        width: 100%;
    }
    .obj-box-desc {
        display: none;
    }
    .obj-box-price-reserve {
        margin-top: 5px;
    }
    .obj-box-price {
        width: 100%;
        line-height: 1;
    }
    .obj-box .btn-buy, .obj-box-review {
        display: block;
        margin-top: 1px;
    }
    .obj-box-review {
        text-align: center;
    }
    .obj-box .btn-buy {
        margin: 0;
    }
    .obj-box .obj-box-wrapper-buy {
        width: 102px;
        margin-left: 10px;
        flex-shrink: 0;
    }
    .obj-box-lbl {
        right: -1px;
    }
    .obj-box-lbl-text {
        font-size: 0.7rem;
        font-weight: 600;
        line-height: 15px;
        height: 18px;
        width: 32px;
    }
    .manage-links {
        text-align: center;
        a {
            display: inline-block;
            padding-left: 0;
        }
    }
    .button {
        padding: 0.75em 1em 0.6em;
    }
    .lot-section {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    @include price(row-reverse);
    
    .list-header {
        display: none;
        background-color: $colorContrast;
        color: #fff;
        font-weight: bold;
        padding: 0.3em 0;
        
        >div {
            font-size: 0.9rem;
            text-transform: uppercase;
        }
        
        .product-list-th-nameshort {
            padding-left: 10px;
        }
        .product-list-th-name {
            display: none;
            padding-left: 81px;
            width: 445px;
        }
        .product-list-th-desc {
            display: none;
            width: 272px;
        }
        .product-list-th-price {
            display: none;
            width: 273px;
            text-align: center;
        }
    }
}
.sh .product-list.list-view-rows .obj-box-wrapper-buy {
    align-self: center;
}
.l-block .list-view-block {
    position: relative;
    padding: 89px 0 1rem;
    
    .obj-box {
        margin: 0 auto ;
        padding-top: 11px;
    }
    
    &::before {
        position: absolute;
        display: block;
        content: '';
        width: 224px;
        height: 109px;
        background: url(/img/shock-price.png) no-repeat 0 0;
        z-index: 9;
        top: -9px;
        right: 0px;
    }
}
#l-block-shock {
    padding-bottom: 1rem;
    
    > .button {
        display: block;
        width: 216px;
        margin: 0 auto;
    }
}
.page-lot-view .l-block .list-view-block {
    padding: 0 0 1rem;
    &::before {
        display: none;
    }
}
.page-lot-view .l-block-caption {
    margin-bottom: 0;
    border-bottom: 0;
}
.mobile {
    .product-list.list-view-rows {
        .obj-box .obj-box-wrapper-buy {
            display: none;
        }
        .obj-box-title {
            .brand-caption {
                font-size: 1em;
            }
            .product-name {
                font-size:  0.9em;
            }
        }
        .obj-box-price-reserve {
            font-size: 0.9em;
        }
        .obj-box-status {
            font-size: 0.7em;
        }
    }
}
@media screen and #{breakpoint(medium)} {
    .product-list {
        margin-top: 0;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .product-list.list-view-list {
        .obj-box-wrapper-except-img {
            margin-left: 235px;
        }
        .obj-box-wrapper-info {
            float: left;
            width: 100%;
            margin-right: -212px;
        }
        .obj-box-wrapper-info-inner {
            margin-right: 230px;
        }
        .obj-box-wrapper-buy {
            float: left;
            width: 200px;
            margin-right: 12px;
        }
        .obj-box .btn-buy, .obj-box-review {
            display: inline-block;
        }
        .obj-box .btn-buy {
            width: 200px;
            margin-top: 25px;
        }
    }
    .product-list .obj-box .btn-buy {
        font-size: 1rem;
    }
    
    .product-list.list-view-rows {
        .obj-box-wrapper-except-img {
            flex-wrap: nowrap;
        }
        .obj-box-wrapper-info {
            width: 40%;
            padding-right: 2em;
        }
        .obj-box-desc {
            display: block;
            width: 30%;
        }
        .obj-box-price {
            width: 30%;
            align-self: center;
            text-align: center;
        }
        .obj-box-status {
            margin-top: 9px;
        }
        @include price(column-reverse);
        
        .list-header {
            display: block;
/*            background-color: $colorContrast;
            color: #fff;
            font-weight: bold;*/
            
            .product-list-th-nameshort {
                display: none;
            }
            .product-list-th-name {
                display: inline-block;
                padding-left: 81px;
                width: 355px;
            }
            .product-list-th-desc, .product-list-th-price {
                display: inline-block;
                width: 206px;
            }
        }
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .product-list.list-view-rows {
        .list-header {
            .product-list-th-name {
                padding-left: 81px;
                width: 445px;
            }
            .product-list-th-desc, .product-list-th-price {
                width: 272px;
            }
        }
    }
}

.mobile {
    .switcher-form {
        select[name="sort"] {
            width: 100%;
            margin-top: 1px;
        }
    }
    .pager-labels {
        font-size: 0.75rem;
    }
}