@function get-list-value($list, $key){
    @each $listKey, $value in $list {
        @if ($listKey == $key) {
            @return $value;
        }
    }
    @return '';
}

@mixin block() {
    background-color: #fff;
    border-radius: 2px;
}

@mixin lbl() {
    background-color: $colorActive;
    border: 1px solid #fff;
    border-radius: 1px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 800;
    height: 21px;
    line-height: 18px;
    text-align: center;
    width: 37px;
}

// отображение цены, если нужно - с меткой Цена. Также для вертикальных меняется порядок отображения старой и новой - сначала новая
@mixin price($direction: row) {
    .price-label {
        display: inline-block;
        vertical-align: top;
    }
    .price-wrapper {
        display: inline-flex;
        flex-direction: $direction;
        align-items: center;
        
        @if $direction == column-reverse {
            .oldprice {
                margin-top: 3px;
                margin-left: 0;
            }  
        }
        @if $direction == row-reverse {
            .oldprice {
                margin-left: 7px;
                margin-top: 0;
            }  
        }
    }
}