.bp {
    position: relative;    
    border: 1px solid $colorBorder;
    border-radius: 3px;
    margin-bottom: 0.8rem;
    padding: 1.8rem 0.7rem 0.7rem;
    font-size: 0.9rem;
    overflow: hidden;
    cursor: pointer;
    
    &.thumbtack {
        border-color: $colorContrast;
    }
    &:hover {
        border: 1px solid $colorActiveSupport;
    }
}
.bp-img {
    display: block;
    float: left;
    padding-right: 0.7rem;
    position: relative;
    
    img {
        max-width: 206px;
    }
}
.bp-img-wrapper {
    position: relative;
}
.thumbtack .bp-img-wrapper:after {
    content: " ";
    position: absolute;
    display: block;
    width: 29px;
    height: 30px;
    right: -5px;
    top: -5px;
    background: url(/img/icons.png) no-repeat -10px -1822px;
}
.typeVideo .bp-img .video-arr {
    position: absolute;
    top: 50%;
    margin-top: -26px;
    left: 50%;
    margin-left: -35px;
    width: 70px;
    height: 52px;
    background-image: url(/img/icons.png);
    background-repeat: no-repeat;
    background-position: -8px -675px;
/*    display: block;
    width: 49px;
    height: 50px;
    color: red;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 50%;
    margin-left: -37px;
    
    &:after {
	content: '';
	display: block;  
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	border-top: 38px solid rgba(255,255,255,0.8);
	border-right: 0 solid transparent;
	border-bottom: 0 solid transparent;
	border-left: 38px solid transparent;
        transform: rotate(45deg);
    }*/
}

.bp.typeTournament {
    img {
        max-height: 142px;
    }
}

.bp-title {
/*    position: relative;*/
    
    h3 {
        font-size: 1.1em;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.3;
        
        a {
            text-decoration: none;
            color: $colorContrast;
            
            &:hover {
                text-decoration: underline;
            }
        }
        /*margin-right: 100px;*/
    }
    .bp-date {
        position: absolute;
        right: 10px;
        top: 4px;
        line-height: 1.3;
        font-size: 0.9em;
        font-weight: 800;
        
        .lbl-warning {
            padding-right: 1rem;
        }
    }
}
.list-container-my-orgs .bp-date .blog-post-map {
    display: none;
}
.bp-serv {
    /*position: absolute;*/
    left: 0;
    bottom: 0;
    display: flex;
}
.bp-serv-a {
    flex: 1 0 auto;
}
.list-container-my-orgs .bp-serv {
    justify-content: flex-end;
}
.bp-author {
    display: inline-block;
    font-size: 0.86em;
    color: $colorRemark;
}
.bp-comments {
    display: inline-block;
    margin-left: 2rem;
    color: $colorRemark;
}
.bp-anonce{
    h1, h2 {
        font-size: 0.9rem;
        text-transform: initial;
        font-weight: normal;
    }
}
.bp-category {
    font-size: .625rem;
    text-transform: uppercase;
    font-style: italic;
}

.typeOrg {
    display: flex;
    
    .bp-title .bp-date .blog-post-map {
        font-weight: normal;
    }
    .bp-img {
        flex: 0 0 auto;
        float: none;
        position: relative;
        width: 120px;
        text-align: center;
        margin-right: 1rem;
        padding-right: 0;
        
        img {
            max-width: 120px;
        }
    }
    .bp-anonce {
        flex: 1 1 auto;
    }
}

.typeOrg.thumbtack {
    .bp-img::after {
        content: " ";
        position: absolute;
        width: 32px; height: 30px;
        background: url(/img/icons.png) no-repeat -8px -1822px;
        top: -7px;
        right: -11px;
    }
}
.bp-anonce-part {
    display: flex;
    margin-bottom: 0.2rem;

    >span {
        flex: 1 1 auto;
        align-self: center;
        line-height: 1.2;
    }

    &::before {
        content: " ";
        width: 32px; height: 32px;
        background-image: url(/img/icons.png);
        background-repeat: no-repeat;
        margin: 3px 1rem 3px 0;
        flex: 0 0 auto;
    }
}
.bp-anonce-address::before {
    background-position: -4px -1968px;
}
.bp-anonce-phone::before {
    background-position: -40px -1968px;
}
.bp-anonce-work::before {
    background-position: -4px -2005px;
}
.bp-anonce-price::before {
    background-position: -40px -2005px;
}

.mobile {
    .bp-img {
        img {
            max-width: 100%;
            width: 100%;
        }
    }
    .bp-article {
        .bp-img {
            float: none;
            padding-right: 0;
            text-align: center;
            margin-bottom: 0.5rem;
        }
    }
    .typeOrg {
        display: block;

        .bp-title h3 {
            font-size: 1em;
        }
        .bp-img {
            width: 70px;
            margin-bottom: 0.5rem;
            float: left;
        }
        .bp-anonce-body {
            clear: left;
        }
        .bp-title .bp-date {
            top: 0;
        }
        .bp-title .bp-date .lbl-warning {
            display: inline-block;
            vertical-align: middle;
            padding: 0 0.2rem;
            line-height: 1;
            width: 215px;
        }
    }
}
/*@media screen and #{breakpoint(medium)} {
    .typeOrg {
        .bp-img {
            width: 120px;
        }
    }
}*/
@media screen and #{breakpoint(large)} {
    .bp {    
        display: flex;
        padding: 1.4rem 1rem 1.3rem 1rem;
    }
    .bp-img {
        padding-right: 1.5rem;
        flex: 0 1 auto;

        img {
            max-width: none;
        }
    }
/*    .bp-title {
        .bp-date {
            top: 0;
        }
        h3 {
            margin-right: 100px;
        }
    }*/
    .bp-anonce {
        /*position: relative;*/
        flex: 1 1 auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .bp-anonce-body {
        flex: 1 0 auto;
    }
    
    .typeOrg {
        .bp-img {
            margin-right: 1.5rem;
        }
        .bp-anonce-body {
            margin-left: 0;
        }
    }
}