.l-item {
    margin-bottom: 0.8rem;
    a {
        color: $colorContrast;
    }
}
.l-item-date {
    display: block;
    font-weight: bold;
    margin-bottom: 0.1rem;
}
.l-item-date-today {
    text-transform: uppercase;
}
a.l-item-new {
    margin-left: 7px;
    &.o-label {
        padding: 0 5px;
    }
}
.l-item-user {
    display: block;
    margin-top: 0.3rem;
}
.l-item-label {
    font-style: italic;
}