.contacts {
    margin-top: 0.3rem;
    
    .icon {
        margin-right: 8px;
    }
    .pseudo-table-cell {
        padding-top: 0.4rem;
        
        a {
            color: $colorContrast;
        }
    }
    .contacts-phones {
        vertical-align: top;
        /*padding-top: 0;*/
        font-size: 0.938rem;
    }
    .icon-info-phone {
        margin-top: 5px;
    }
    .rem-dark {
        margin-top: 0.6rem;
    }
}
.contacts-note {
    border: 1px solid #000;
    border-radius: 2px;
    padding: 1rem;
    margin-top: 0.7rem;
    
    p {
        font-size: 0.813rem;
        line-height: 1.154;
    }
}
.contacts-social-wrapper {
    margin-top: 1.8rem;
    padding: 0.5rem;
    border: 1px solid #000;
    border-radius: 2px;
    
    a {
        text-decoration: none;
    }
    .soc-bl {
        margin-top: 0.5rem;
    }
    .contacts-social {
        line-height: 1.429
    }
}

@media screen and (min-width: 600px) {
    .contacts-social-wrapper {
        display: flex;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .contacts {
        margin-right: 1rem;
    }     
/*    .contacts-social-wrapper {
        display: flex;
        
        .contacts-widget {
            flex: 0 1 60%;
        }
        .contacts-social {
            flex: 0 1 40%;
            line-height: 1.429
        }
    }*/
    
}

/*.contacts-widget {
    .widget {
        margin-bottom: 0;
        padding: 0.25rem;
        
        & > a {
            padding: 0;
            width: auto;
            height: auto;
            
            > span {
                padding: 0;
                font-size: 0.938rem;
            }
            > span.rem {
                color: $colorContrast;
                font-size: 0.75rem;
                line-height: 1.5 !important;
                margin-bottom: 2px;
                margin-top: 1px;
                font-weight: normal;
            }
        }
    }
    .widget-header h3 {
        float: none;
        color: $colorContrast;
        margin-bottom: 0;
        margin-top: -6px;
        
        .icon-youtube-light {
            right: auto;
            left: auto;
        }
    }
}*/