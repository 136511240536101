@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300');
/*@import "utilites/index";*/ 
/*@import './node_modules/foundation-sites/scss/foundation';*/

/* подключаем foundation ----------------------------------------------------*/
/* код ниже взят практически весь из foundation.scss, выкинуты только те модули которые не нужны */
/*@import './node_modules/foundation-sites/_vendor/normalize-scss/sass/normalize';*/
@import './node_modules/foundation-sites/scss/vendor/normalize';
@import './node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import './node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/true';
@import './node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/contain';
@import './node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/purge';
@import './node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/remove';
@import './node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/replace';
@import './node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/to-list';

// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import './node_modules/foundation-sites/scss/settings/settings';
/* подключаем собственный конфиг переменных, которые должны перекрыть настройки по умолчанию */
@import "variables";


// Sass utilities
@import './node_modules/foundation-sites/scss/util/util';

// Global variables and styles
@import './node_modules/foundation-sites/scss/global';


// Components
/*@import 'grid/grid';*/
@import './node_modules/foundation-sites/scss/xy-grid/xy-grid';
@import './node_modules/foundation-sites/scss/typography/typography';
@import './node_modules/foundation-sites/scss/forms/forms';
@import './node_modules/foundation-sites/scss/components/visibility';
@import './node_modules/foundation-sites/scss/components/float';
@import './node_modules/foundation-sites/scss/components/button';
/*@import 'components/button-group';*/
/*@import 'components/accordion-menu';*/
/*@import 'components/accordion';*/
/*@import 'components/badge';*/
@import './node_modules/foundation-sites/scss/components/breadcrumbs';
/*@import 'components/callout';*/
/*@import 'components/card';*/
/*@import 'components/close-button';*/
/*@import 'components/drilldown';*/
/*@import 'components/dropdown-menu';*/
@import './node_modules/foundation-sites/scss/components/dropdown';
@import './node_modules/foundation-sites/scss/components/flex';
@import './node_modules/foundation-sites/scss/components/responsive-embed';
/*@import 'components/label';*/
@import './node_modules/foundation-sites/scss/components/media-object';
@import './node_modules/foundation-sites/scss/components/menu';
/*@import 'components/menu-icon';*/
/*@import 'components/off-canvas';*/
/*@import 'components/orbit';*/
/*@import 'components/pagination';*/
@import './node_modules/foundation-sites/scss/components/progress-bar';
/*@import './node_modules/foundation-sites/scss/components/reveal';*/
@import './node_modules/foundation-sites/scss/components/slider';
/*@import 'components/sticky';*/
@import './node_modules/foundation-sites/scss/components/switch';
@import './node_modules/foundation-sites/scss/components/table';
@import './node_modules/foundation-sites/scss/components/tabs';
/*@import 'components/title-bar';*/
/*@import 'components/top-bar';*/
/*@import 'components/thumbnail';*/
@import './node_modules/foundation-sites/scss/components/tooltip';
/*@import 'prototype/prototype';*/

@mixin foundation-custom(
  $flex: true,
  $prototype: false
) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  /*@include foundation-accordion;*/
  /*@include foundation-accordion-menu;*/
  /*@include foundation-badge;*/
  @include foundation-breadcrumbs;
  /*@include foundation-button-group;*/
  /*@include foundation-callout;*/
  /*@include foundation-card;*/
  /*@include foundation-close-button;*/
  @include foundation-menu;
  /*@include foundation-menu-icon;*/
  /*@include foundation-drilldown-menu;*/
  @include foundation-dropdown;
  /*@include foundation-dropdown-menu;*/
  @include foundation-responsive-embed;
  /*@include foundation-label;*/
  @include foundation-media-object;
  /*@include foundation-off-canvas;*/
  /*@include foundation-orbit;*/
  /*@include foundation-pagination;*/
  @include foundation-progress-bar;
  @include foundation-progress-element; // добавлено вручную
  @include foundation-slider;
  /*@include foundation-sticky;*/
  /*@include foundation-reveal;*/
  @include foundation-switch;
  @include foundation-table;
  @include foundation-tabs;
  /*@include foundation-thumbnail;*/
  /*@include foundation-title-bar;*/
  @include foundation-tooltip;
  /*@include foundation-top-bar;*/
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex {
    @include foundation-flex-classes;
  }

  @if $prototype {
    @include foundation-prototype-classes;
  }
}

@include foundation-custom;
/* -------------------------------------------------------------------------- */

/*@import "variables";*/

@import "utils/index";
@import "layout/index";
@import "blocks/index";
@import "components/index";
@import "pages/index";
@import "vendor/fancybox/index";
@import "vendor/index";