.product-wrapper {
    margin-top: 21px;
    
    .price-wrapper {
        font-size: 1.25rem;
        display: inline-block;
        
        .price-label {
            padding-right: 0.25rem;
        }
    }
    .product-status {
        display: inline-block;
        margin-left: 0.6rem;
        font-size: 0.8rem;
        &::first-letter {
            text-transform: uppercase;
        }
    }
    .product-img {
        margin-top: 0.5rem;
        text-align: center;
        position: relative;
    }
    .btn-buy {
        width: 12.5rem;
        font-weight: bold;
    }
    .product-lbl {
        position: absolute;
        right: 0px;
        top: -1px;
        display: flex;
    }
    .product-lbl-text {
        @include lbl;
    }
}
.product-desc {   
    p, ul {
        font-family: "Open Sans" !important;
        font-size: 0.9rem !important;
        color: $colorContrast !important;
    }
    table {
        @include table-scroll;
    }
}

.ch-table {
    width: auto;
    td {
        background: transparent;
        &.prod-ch-termin {
            padding: 0.33rem 0.5rem 0.33rem 0.15rem;
        }
    }
    tbody td {
        padding: 0.1rem 0.15rem 0.1rem;
    }
    .scale {
        width: 200px;
        border: 1px solid $colorBorder;
        border-radius: 2px;
        padding: 0 8px 5px;
        text-align: center;
        
        span {
            display: block;
            line-height: 1;
            padding: 2px 0 7px;
        }
    }
    .icon-question {
        vertical-align: sub;
    }
}
.prod-ch-termin {
    text-transform: uppercase;
    font-weight: 600;
    vertical-align: middle;
}
.prod-ch-value {
    padding-left: 1em;
    font-size: 0.9rem;
}
.mobile { 
    .ch-table {
        tbody {
            padding: 0.5rem 0.3rem 0.3rem;
        }
        td {
            padding: 0.1rem;
        }
        .prod-ch-termin {
            font-size: 0.9rem;
            padding-right: 0.5rem;
        }
        .scale {
            width: 130px;
        }
        .prod-ch-que {
            padding-right: 0.3rem;
        }
    }
    .product-gal {
        margin-left: auto;
        margin-right: auto;
    }
}

.product .tabs {
    margin-top: 0.85rem;
    
    .tabs-title:first-child a {
        padding-left: 0;
    }
    .tabs-title > a {
        font-size: 0.938rem;
        padding: 0.75rem 0.5rem 8px;
    }
}
.product-caption {
    font-size: 0.938rem;
    font-weight: bold;
    text-transform: uppercase;
    /*color: $colorPrimary;*/
    padding-right: 15px;
}
/*#secondCh .product-caption {
    align-self: flex-start;
    line-height: 28px;
}*/
.product-info-buy {
    margin-top: 1.2rem;
}
.product-info-phone {
    margin-top: 1.65rem;
    font-size: 0.938rem;
}
.product-info-question {
    margin-top: 1.25rem;
}
.product-qnt {
    display: flex;
    align-items: center;
    margin-top: 1.65rem;//1rem;
    
    >span {
        flex-shrink: 0;
    }
    .input-group {
        flex-basis: 90px;
        /*padding-left: 35px;*/
        margin-bottom: 0;
    }
}
.product-info-phone strong {
    display: block;
}

#variants {
    margin-top: 0.25rem;
}
.product-help-block {
    margin-top: 1rem;
    font-size: 0.938rem;
    
    .rem {
        line-height: 1.3;
        margin-top: 2px;
    }
}

.product-variant {
    margin-top: 0.75rem;
        
    .option {
        cursor: pointer;
        
        input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            margin: 0;
/*            visibility: hidden;
            width: 0;*/
        }
        label {
            border-radius: 2px;
            margin: 0 3px 0 0;
            border: 1px solid $colorContrast;

            >span {
                display: block;
            }
        }
    }
    .product-variant-values {
        .option {
            display: inline-block;
            position: relative;
            
            label {
                >span {
                    display: block;
                    text-align: center;
                    min-width: 28px;
                    min-height: 28px;
                    padding: 0 0.2rem;
                    line-height: 28px;
                    font-weight: 600;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .product-variant-values-set-by-status {
        margin-bottom: 3px;
        
        span {
            font-size: 0.8rem;
        }
    }

    &.is-color {
        .product-variant-values {
            margin-top: 1rem;
        }
    }
    .option-wrapper{
        display: flex;
        cursor: pointer;
        margin-bottom: 8px;
        
        .option {
            width: 22px;
            height: 24px;
        }
        .option-hint {
            font-size: 0.8rem;
/*            padding-left: 0.5rem;
            padding-right: 0.5rem;*/
            width: calc(70% - 20px);
            
            span {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                
                display: inline-block;
                line-height: 22px;
                height: 24px;
                border-radius: 0 2px 2px 0;
            }
        }
        a.option-expander {
            color: $colorContrast;
            text-decoration: underline;
            font-size: 0.8rem;
        }
        a.option-expander:hover {
            text-decoration: none;
        }
    }
    .option.option-color {
        display: block;
        padding: 2px;
        
        &.checked {
            background-color: $colorBorder;
        }
        
        input ~ label {
            border: 1px solid #fff !important;
            background-color: transparent !important;
        }
        input:checked ~ label {
            border: 1px solid $colorContrast !important;
            background-color: transparent !important;
        }
        label {
            border: 1px solid #fff;
            /*padding: 1px;*/

            >span {
                height: 18px;
                width: 18px;
                min-width: 18px;
                min-height: 18px;
                padding: 0;
                line-height: 1;
                border: 1px solid $colorBorder;
                border-radius: 2px;
                
                &.color-half {
                    height: 9px;
                    min-height: 9px;
                }
                &.color-half:first-child {
                    border-bottom: 0;
                    border-radius: 2px 2px 0 0;
                }
                &.color-half:last-child {
                    border-top: 0;
                    border-radius: 0 0 2px 2px;
                }
            }
        }
        .option-hint {
/*            padding-left: 0;
            padding-right: 0;*/

            span {
            }
        }
        &.checked + .option-hint span {
            background-color: $colorBorder;
        }
    }
}
.product-variant-values-set {
    display: none;
    margin: 0 0.5rem 0.7rem 1.8rem;
}

.no-color .product-variant-values { //#secondCh 
    margin-top: 1.1rem;//1.2rem;
}

// statuses
.product-variant-values {
    // standard view
    .status-0.option label {
        color: $colorPrimary;
        border: 1px solid $colorPrimary;
    }
    .status-2.option label, .status-5.option label {
        color: $colorActive; 
        border: 1px solid $colorActive;
    }
    .status-1.option label, .status-3.option label, .status-4.option label, .status-6.option label {
        color: $colorRemark; 
        border: 1px solid $colorRemark;
    }
    
    // checked view
    .status-0.option input:checked ~ label {
        background-color: $colorPrimary;
        border: 1px solid $colorPrimary;
        color: #fff;
    }
    .status-2.option input:checked ~ label, 
    .status-5.option input:checked ~ label {
        background-color: $colorActive;
        border: 1px solid $colorActive;
        color: #fff;
    }
    .status-1.option input:checked ~ label, 
    .status-3.option input:checked ~ label, 
    .status-4.option input:checked ~ label, 
    .status-6.option input:checked ~ label {
        background-color: $colorRemark;
        border: 1px solid $colorRemark;
        color: #fff;
    } 
/*    .option:hover input:not(:checked) ~ label { //.option input:focus ~ label
        border: 1px solid $colorActive;
        color: $colorActive;
    }*/
}
/*.product-variant-values {    
    // hovered view
    .status-0.option:not(.is-color):hover input:not(:checked) ~ label {
        color: #fff;
        background-color: $colorPrimary;
    }
    .status-2.option:not(.is-color):hover input:not(:checked) ~ label, 
    .status-5.option:not(.is-color):hover input:not(:checked) ~ label {
        color: #fff;
        background-color: $colorActive;
    }
    .status-1.option:not(.is-color):hover input:not(:checked) ~ label, 
    .status-3.option:not(.is-color):hover input:not(:checked) ~ label, 
    .status-4.option:not(.is-color):hover input:not(:checked) ~ label, 
    .status-6.option:not(.is-color):hover input:not(:checked) ~ label {
        color: #fff;
        background-color: $colorRemark;
    }
}*/


.product-gal {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 289px;
}
@media screen and #{breakpoint(medium)} {
    .product-wrapper {
        .product-img {
            text-align: left;
        }
    }
    .product-gal {
        width: 310px;
    }
    .ch-table {
        .prod-ch-que {
            padding-right: 0.5rem;
        }
    }
}
@media screen and #{breakpoint(large)} {
    .product-card-l {
        width: 310px;
    }
    .sh .product-card-l {
        width: 312px;
    }
    .product-card-r {
        padding-left: 2.5rem;//2.5rem;
    }
    .product-gal {
        margin-bottom: 0;
    }
    .product-wrapper {
        .product-img {
            text-align: center;
        }
    }
    .product-variant .option-wrapper .option-hint {
        width: calc(65% - 20px);
    }
}
@media screen and #{breakpoint(xlarge)} {
    .product-card-r {
        padding-left: 3.25rem;//2.5rem;
    }
    .product-info-phone strong {
        display: inline;
    }
    .product .tabs {
        .tabs-title > a {
            font-size: 1rem;
            padding: 0.75rem 1rem 8px;
        }
    }   
}
@media screen and #{breakpoint(xxxlarge)} {
    .product-variant .option-wrapper .option-hint {
        width: calc(50% - 20px);
    } 
}