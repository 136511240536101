.switch-review {
    margin-bottom: 0.5rem;
    margin-top: 0.1rem;
    
    .switch-paddle {
        width: 8rem;
    }
    .switch-inactive {
        right: 6%;
    }
    
    input:checked~.switch-paddle::after {
        left: 6.25rem;
    }
}

