.page-home, .standard-page {
    .col-r {
        .block:first-child {
            margin-top: 2.5rem
        }
        .block {
            margin-bottom: 0.3rem;
        }
        .block:last-child {
            margin-bottom: 0;
        }
    }
    .block-header {
        margin-top: 0;
    }
}
.page-home.mobile, .standard-page.mobile  {
    .col-r {
        .block:first-child {
            margin-top: 0;
        }
    }
    .l-block-transparent {
        margin-bottom: 0;
    }
}
:not(.sh).page-home.mobile {
    .block {
        padding: 0;
    }
}
@media screen and #{breakpoint(medium)} {
    .page-home, .standard-page {
        .col-r {
            .block:first-child {
                margin-top: 2.5rem;
            }
            .block {
                margin-bottom: 0.625rem;
            }
        }
    }
}
@media screen and #{breakpoint(large)} {
    .page-home, .standard-page {
        .col-r {
            .block:first-child {
                margin-top: 0;
            }
            .block-header {
                padding-top: 1rem;
            }
            .block {
                margin-bottom: 1.25rem;
            }
        }
    }
}