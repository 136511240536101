/* Slideshow button */

.fancybox-button--play {}

.fancybox-button--play::before,
.fancybox-button--pause::before {
	top: calc(50% - 6px);
	left: calc(50% - 4px);
	background: transparent;
}

.fancybox-button--play::before {
	width: 0;
	height: 0;
	border-top: 6px inset transparent;
	border-bottom: 6px inset transparent;
	border-left: 10px solid;
	border-radius: 1px;
}

.fancybox-button--pause::before {
	width: 7px;
	height: 11px;
	border-style: solid;
	border-width: 0 2px 0 2px;
}
