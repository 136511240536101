.page-trnCalendar {
    .block-header-rblock {
        width: 153px;
    }
}

.calendar-filter {
    border-bottom: 1px solid $colorBorder;
    margin-bottom: 0.7rem;
    padding-top: 0.5rem;
    
    input, select {
        margin-bottom: 0.15rem;
        margin-right: 0.15rem;
    }
    .filter-button {
        margin: 0.7rem 0;
        
        input {
            margin-bottom: 0;
            width: 100px;
            height: 1.7rem;
        }
    }
/*    a.link-reset {
        color: $colorActiveSupport;
        font-size: 0.8rem;
    }*/
    .control--checkbox {
        font-size: 0.7rem;
        text-transform: uppercase;
        
        .icon {
            width: 26px; height: 26px;
            margin-right: 3px; 
            margin-left: 5px;
        }
    }
}

.calendar-filter-fields-grid {
    .cell {
        display: flex;
    }
}
.calendar-filter-chk-grid {
    margin-top: 0.7rem;
}

.year-calendar {
    font-size: 0.9rem;
    .icon {
        width: 26px;
        height: 26px;
    }
}
h3.calendar-list-month {
    font-size: 1rem;
    font-weight: bold;
    margin: 1.5rem 0 0.25rem;
}
.calendar-list-month-wrapper {
    border: 1px solid $colorBorder;
    border-radius: 2px;
    
    .grid-x {
        padding: 0.25rem 0;
    }
}
.calendar-date {
    width: 41px;
    height: 50px;
    margin: 0 1rem 0.15rem 0.7rem;
    text-align: center;
    background: url(/img/icons.png) no-repeat -5px -2045px;
    line-height: 1;
    
    >span {
        display: block;
    }
    .calendar-date-date {
        padding-top: 16px;
        font-size: 1rem;
        font-weight: 600;
    }
    .calendar-date-day {
        font-size: 0.8rem;
        text-transform: lowercase;
        color: $colorRemark;
    }
}
.calendar-icon-col {
    width: 40px;
    text-align: center;
}

@media screen and #{breakpoint(large)} {
    .page-trnCalendar {
        .block-header-rblock {
            width: auto;
        }
    }
    .calendar-filter {
        .control--checkbox {
            margin-left: 1rem;
            margin-bottom: 0.2rem;
        }
    }
    .calendar-filter-chk-grid {
        margin-top: 0.2rem;
    }
    .calendar-name-wrapper {
        display: flex;
        align-items: center;
        min-height: 50px;
    }
    .calendar-city {
        flex-shrink: 0;
        width: 100px;
        text-align: center;
    }
    .calendar-name {
        flex: auto;
    }
}