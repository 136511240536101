// переопределяем вид боксов для view=rows
.cart.product-list.list-view-rows {
    .obj-box {
        display: flex;
        /*flex-direction: row;*/
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
    }
    .obj-box:hover {
        border: 1px solid $colorBorder;
    }
    .obj-box-wrapper-info {
        margin-bottom: 1rem;
    }
    .obj-box-title .brand-caption {
        margin-bottom: 0;
    }
    .obj-box-cart-price{
        font-size: 0.9rem;
        font-weight: bold;
        text-align: left;
        width: auto;
        line-height: 1;
    }
    .obj-box-cart-del{
        width: 16px;
        a {
            display: block;
        }
    }
    .obj-box-cart-qnt {
        .input-group {
            margin-bottom: 0;
        }
/*        width: 125px;*/
        text-align: center;
    }
    .obj-box-cart-variant {
        margin-top: 5px;
        font-size: 0.9rem;
        font-style: italic;
        line-height: 1.1;
    }
    .obj-box-wrapper-except-img {
        align-items: center;
    }
    .list-header {
        padding-left: 8px;
        padding-right: 8px;
        
        .product-list-th-cart-nameshort {
            display: inline-block;
            width: 81px;
        }
        .product-list-th-cart-name {
            display: none;
        }
        .product-list-th-cart-price {
            display: inline-block;
            text-align: center;
            width: 95px;
        }
        .product-list-th-cart-qnt {
            display: inline-block;
            text-align: center;
            width: 125px;
        }
        .product-list-th-cart-del {
            display: inline-block;
            text-align: center;
            width: 30px;
        }
    }
    
    .obj-box-img {
        flex: 0 0 62px;
        /*margin-right: 0;*/
        /*align-self: auto;*/
    }
    .obj-box-wrapper-except-img {
        flex: 1 1 1px;
        /*align-self: auto;*/
    }
    .obj-box-item-footer {
        flex: 1 1 100%;
        /*align-self: auto;*/
    }
    .price-wrapper {
        flex-direction: column-reverse;
        align-items: left;
            
        .oldprice {
            margin-left: 0;
        }
    }
}
.cart-summary {
    margin-top: 1rem;
    font-size: 0.9rem;
/*    .subtitle {
        font-size: 0.875rem;
    }*/
    .caption {
        padding-right: 2.125rem;
        text-align: right;
    }
}
.cart-summary-total {
    text-align: center;
}

.cart-book-continue-wrapper{
    text-align: center;
    margin-bottom: 0.7rem;
}
.cart-buttons-wrapper {
    overflow: hidden;
}
.cart-clear-button-wrapper {

}
.cart-buy-button-wrapper {
    text-align: right;
}

.cart-book-continue {
    text-align: center;
    padding: 1rem 0;
}
.cart-clear-button {
    max-width: 75px;
    text-align: center;
    line-height: 1.1;
}
.cart-buy-button {
    text-align: right;
}


.sub-total {
    font-size: 1.063rem;
    font-weight: bold;
}
.overall-total {
    font-size: 1.2rem;
    font-weight: bold;
    color: $colorPrimary;
}
.price-currency {
    text-transform: lowercase;
}
.sum-not-enough .overall-total, .sum-not-enough-text {
    color: #f8a038;
}
.sne-hint {
    /*box-shadow: 0 1px 9px 2px #EC8408;*/
    margin-left: 5px;
    vertical-align: text-bottom;
    border-radius: 10px;
    margin-right: 3px;
    border: 1px solid #ec8408;
    width: 20px;
    height: 20px;
}
.help-cart-continue {
    margin-top: 0.7rem;
    text-align: center;
}
#lblTotal .subtitle {
    margin-right: 0.5rem;
}
#deliveryWrapper span {
    padding-bottom: 1rem;
}

.mobile .cart.product-list.list-view-rows {
    .obj-box-cart-qnt {
        .input-number-decrement, .input-number-increment {
            display: none;
        }
    }
}
@media screen and (min-width: 420px) {
    .cart-clear-button {
        max-width: 100%;
        text-align: left;
    }
}
@media screen and #{breakpoint(medium)} {
    .cart.product-list.list-view-rows {
        .obj-box-cart-price{
            font-size: 1rem;
            width: 90px;
        }
        .obj-box-cart-qnt {
            width: 125px;            
        }
    }
    .cart-summary-total {
        float: right;
        text-align: right;
    }
    .help-cart-continue {
        float: left;
        text-align: left;
    }
/*    .cart-book-continue-wrapper {
        text-align: left;
        float: left;
        margin-bottom: 0;
    }
    .cart-buy-button-wrapper {
        text-align: right;
        float: right;
    }*/
    .cart-book-continue {
        text-align: left;
    }
    .cart-book {
        margin-top: 0.5rem;
        .button {
            padding: 0.85em 1.5em 0.7em;
            margin-top: 0;
        }
        a {
            display: inline-block;
            margin-top: 0.5rem;
        }
    }
}
@media screen and #{breakpoint(large)} {
    .cart-summary {
        margin-bottom: 1rem;
    }
    .cart-clear-button {
        text-align: right;
        padding-right: 2rem;
    }
    .cart-book-continue {
        padding: 0;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .cart.product-list.list-view-rows {
        .obj-box-wrapper-except-img {
            flex-wrap: nowrap;
        }
        .obj-box-wrapper-info {
            width: 45%;
            padding-right: 2em;
            margin-bottom: 0;
        }
        .obj-box-cart-price, .obj-box-cart-qnt
        {
            width: 17%;
            align-self: center;
            text-align: center;
        }
        .obj-box-cart-del {
            width: 4%;
            align-self: center;
        }
        
        .list-header {
            padding-left: 81px;
            
            .product-list-th-cart-nameshort {
                display: none;
            }
            .product-list-th-cart-name {
                display: inline-block;
                width: 45%;
            }
            .product-list-th-cart-price {
                width: 17%;
            }
            .product-list-th-cart-qnt {
                width: 17%;
            }
            .product-list-th-cart-del {
                width: 17%;
            }
        }
        .price-wrapper {
            align-items: center;
        }
    }
    .cart-summary {
        margin-top: 1.5rem;
        .subtitle {
            font-size: 0.875rem;
        }
    }
    .cart-book {
        margin-top: 1rem;
        .button {
            margin-top: 0.3rem;
        }
        a {
            margin-top: 0;
        }
    }
    .help-cart-continue {
        margin-top: 0;
    }
}
/*@media screen and #{breakpoint(xxxlarge)} {
    .cart.product-list.list-view-rows {
        .list-header {
        }
    }
}*/