.form-booking {
    .form-block {
        margin-bottom: 1rem;
    }
    textarea[name="edt_Address1"] {
        height: 48px;
    }
    textarea[name="edt_Text"] {
        height: 60px;
    }

/*    .exp-cart-header-total {
        height: 0;
        visibility: hidden;
    }*/
    .expandable-block {
        .product-list {
            margin-top: 0;
        }
        .cart-summary {
            display: none;
        }
    }
    #frmCallMe {
        margin-left: 1.6rem;
        margin-top: 0.8rem;
        
        .hint-popup, .form-error {
            max-width: 255px;            
        }
    }
    #deliveryNotice {
        margin-top: 1rem;
        
        .msg {
            font-size: 0.813rem;
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .form-booking {
/*        .exp-cart-header-total {
            height: auto;
            visibility: visible;
        }*/
        .expandable-block .cart-summary {
            display: block;
        }
        .cart-summary-out{
            display: none;
        }
        #frmCallMe {
            margin-left: 1rem;
            margin-top: 3.1rem;
        }
        
        #deliveryNotice {
            .msg {
                font-size: 0.9rem;
            }
        }
    }
}