.tag-menu-block {
    .menu-caption {
        font-size: .97rem;
        text-transform: uppercase;
        padding: .785rem 0 .5rem 0;
        line-height: 1.2;
    }
}
.tag-menu {
    display: flex;
    flex-wrap: wrap;
}
.tag-menu__item {
    font-weight: normal; 
    font-size: 0.9rem;
    line-height: 1.1;
    padding-bottom: 7px;
    flex:50%; 
    padding-right: 3px;
}

