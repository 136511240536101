.comm-adm {
    display: inline-block;
    padding-right: 10px;
}

#scrollup {
    position: fixed;
    display: none;
    bottom: 42px;
    right: 3px;
    width: 41px;
    height: 46px;
    outline: medium none !important;
    background-image: url(/img/icons.png);
    background-repeat: no-repeat;
    background-position: -6px -1262px;
/*    text-indent: -9999px;*/
    z-index: 9999;
}
#scrollup:hover {
    background-position: -58px -1262px;
}
#notif-float {
    display: none;
    position: fixed;
    width: 28px;
    height: 32px;
    bottom: 116px;
    right: 11px;
    background-image: url(/img/bell.png);
    text-decoration: none;
    z-index: 9998;
    
    span {
        position: absolute;
        display: block;
        min-width: 20px;
        height: 20px;
        padding: 0 3px;
        top: 1px;
        right: -7px;
        font-size: 0.8rem;
        font-weight: 600;
        color: #fff;
        border: 1px solid #fff;
        background-color: $colorActiveSupport;
        border-radius: 10px;
        outline: medium none !important;
        text-align: center;
        z-index: 9999;
    }
    &:hover span {
        background-color: $colorActive;
    }
}

#get-consulting {
    position: fixed;
    bottom: 2px;
    right: 3px;
    padding: 0;
    z-index: 999;
    border-left: 0;
    border-right: 0;
    
    .name {
        padding: 0.45rem 0.5rem 0.45rem 0.5rem;
        display: inline-block;
        vertical-align: middle;
        display: none;
        font-size: 0.9rem;
        font-weight: bold;
    }
    .icon-consult {
        margin: 0 6px;
    }
}
.tooltip {
    z-index: 9996;
}
@media screen and #{breakpoint(medium)} {
    #scrollup {
       bottom: 50px;
       width: 46px;
       background-position: -3px -1262px;
    }
    #scrollup:hover {
        background-position: -55px -1262px;
    }
    #get-consulting {
        /*border-bottom: 3px solid $colorPrimary;*/
        .name {
            display: inline-block;
            border-right: 1px solid #66AC4A;
        }
        .icon-consult {
            /*border-left: 1px solid #66AC4A;*/
            margin: 0 3px 0 0px;
        }
    }
    
}