.header-top {
    background-color: $colorFooter;
    
    .button {
        background-color: #fff;
        color: $colorFooter;
        margin: 0;
        font-weight: 600;
        font-size: 0.9rem;
        text-transform: none;
    }
    .button:hover {
        color: $colorPrimary;
    }
    .btn-reg {
        padding: 0.29rem 1rem;
        /*width: 8rem;*/
        
        &.logged {
            padding: .25rem 1rem;
            /* height: 26px; */
            vertical-align: middle;
            /*position: relative;*/
        }
        .icon {
            vertical-align: baseline;
            margin-right: 0;
            margin-left: 3px;
        }
    }
    .btn-login {
        padding: 0.29rem 1rem;
        /*width: 8rem;*/
    }
    .pm {
        font-weight: normal;
        color: $colorActiveSupport;
    }
    
/*    .menu-profile {
        position: absolute;
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 12rem;
        z-index: 9995;
        background-color: #fff;
        left: 0;
        top: 26px;
    }*/
}
#menu-top-bar {
    .menu a {
        color: $colorMenu;
        text-transform: uppercase;
        padding: 0.75rem 0.5rem 0.8rem;
        font-size: 0.9rem;
        font-weight: 600;
        
        &:hover {
            color: $colorPrimary;
        }
    }
    .menu li:first-child {
        a {
            padding-left: 0;
        }
    }
}
#menu-langs.menu {
    line-height: 1.3;
    
    li {
        margin-left: 4px;
    }
    a, >li>span {
        padding: .15rem 0.5rem;
        font-family: "Open Sans Condensed",sans-serif;
        font-size: 1.2rem;
        border-radius: 2px;
        border: 1px solid $colorPrimary;
    }
    a {
        color: $colorPrimary;
    }
    >li>span, a:hover {
        background-color: $colorPrimary;
        color: #fff;
        display: block;
        line-height: 1;
    }
}

.menu-profile {
    text-align: left;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    font-size: 0.86rem;
    /*font-weight: 600;*/
    font-style: italic;
    padding: 0;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        ul {
            padding: 0 0 0 15px;
        }
    }
    >ul>li {
        border-top: 1px solid $colorBorder;
        padding: 0.8rem 0.3rem 0.5rem 0.8rem;
    }
    >ul>li:first-child {
        border-top: 0;
    }
    .menu a {
        color: $colorFooter;
        font-style: normal;
        padding: .45rem .5rem .45rem 0;
        text-transform: none;
        font-weight: 600;
        font-size: 0.8rem;

        &:hover {
            text-decoration: underline;
        }
    }
}
@media screen and #{breakpoint(medium)} {
    .header-top {
        .btn-reg {
            //padding: 0.29rem 3.425rem;//0.29rem 3.75rem;
            min-width: 11rem;
        }
        .btn-login {
            //padding: 0.29rem 2.5rem;//0.29rem 2.625rem;
            width: 8rem;
        }
    }
    #menu-top-bar {
        .menu a {
            padding: 0.75rem 1rem 0.8rem;
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .header-top {
        .btn-reg {
            min-width: 13rem;
        }
    }
    #menu-top-bar {
        .menu a {
            padding: 0.75rem 1.4rem 0.8rem;//0.75rem 1.3rem 0.8rem;
        }
    }
}