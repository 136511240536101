[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea, select, a.select-emul {
    height: 1.875rem;
    margin-bottom: 0.35rem;
    padding: 0.2rem 0.5rem 0.2rem 0.7rem;
    font-style: italic;
    line-height: 1.2;
}
input::placeholder, textarea::placeholder
{
    opacity: 1; // firefox: fix pleceholder text color
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 50px #fff;
}
select {
    padding: 0.2rem 1.5rem 0.2rem 0.7rem;
    
    option, optgroup {
        color: $colorContrast;
    }
    option[data-default] {
        color: $medium-gray;
    }
}
textarea {
    resize: vertical;
}
input[name="edt_AdditionKText"] {
    display: none;
}

.loading {
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg>');
    background-size: 18px 18px;

    svg path, svg rect {
      fill: $colorActive;
    }
}


fieldset legend {
    font-size: 1.063rem;
    font-weight: 700;
    line-height: 1.118;
    margin-bottom: 1.1rem;
    padding-top: 0.8rem;
    
    .rem {
        font-weight: normal;
    }
}

.form-checkbox-block label {
    padding-left: 25px;
}
.page-getForm .form-checkbox-block label {
    color: #999999;
    font-size: 0.813rem;
    /*line-height: 1.2;*/
}

.field, .form-group {
    position: relative;
    padding-right: 9px;
    
    .rem {
        font-size: 0.813rem;
        line-height: 1.154;
    }
    
    &.required::after, &.required-pseudo:after {
        content: "*";
        position: absolute;
        right: -2px;
        top: 6px;
        color: $colorActiveSupport;
        font-size: 0.8rem;
    }
}
.field-margin-top {
    margin-top: 1rem;
}
.field-helper {
    display: block;
    margin-bottom: 0.5rem;
    margin-top: -0.2rem;
}
.field.left-col-padding {
    input {
        width: calc(100% - 18px);
    }
    &.required::after, &.required-pseudo::after {
        right: 16px;
    }
}
.field-header-right-section {    
    padding-top: 0.8rem;
    a {
        font-size: .8rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    .dropdown-pane {
        text-align: left;
        font-size: 0.86rem;
    }
}
.form-gutter-5 {
    .field, .form-group {
        margin-right: 5px;
    }
}
.cell.no-margin .field, .cell.no-margin .form-group {
    margin-right: 0;
    padding-right: 0;
}

.form-block-header {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.357;
    color: #fff;
    background-color: $colorContrast;
    text-transform: uppercase;
    /*padding: 0.35rem 0.6rem;*/
    padding: 0.4rem 0.6rem 0.4rem;
    
    a.expander {
        text-decoration: none;
        color: #fff;
        display: flex;
        padding-top: 0.4rem;
    }
}
.form-block {
    border: 1px solid $colorBorder;
    padding: 0.5rem;
}
.form-block-header-note {
    color: $colorActive;
}

.form-side-block {
    border: 1px solid $colorBorder;
    border-radius: 2px;
    padding: 0.5rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    
    .field {
        padding-right: 0;
    }
    input {
        max-width: 255px;
    }
    button {
        width: 100%;
        max-width: 255px;
        padding: 0.4em 1em 0.35em;
    }
    .field .hint-popup {
        left: 0;
        right: 0;
    }
}
.form-side-block-header {
    font-size: 0.96rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0.3rem;
}
.form-side-block-help {
    margin-bottom: 0.5rem;
}
.form-side-block-follower {
    margin-top: 0.5rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
}

.form-field-hint {
    line-height: 0.85;
}

.is-invalid-input:not(:focus) {
    background-color: #fff;
}
.is-invalid-input:not(:focus)::placeholder {
    color: $colorRemark;
}
.is-invalid-input:focus {
    border-color: $colorActiveSupport;
}
.form-error {
    margin-top: -0.3rem;
    margin-bottom: 0.5rem;
    text-align: right;
}
.form-checkbox-block .form-error {
    text-align: left;
}
.error {
    color: $colorActiveSupport;
}

.help-block {
    font-size: 0.8rem;
    line-height: 1;
}
.has-error {
    input, select, textarea {
        border-color: $colorActiveSupport;
    }
    .help-block {
        color: $colorActiveSupport;
        margin-bottom: 0.5rem;
    }
}


@media screen and #{breakpoint(xlarge)} {
    .first-col fieldset {
        margin-right: 0.8rem;
    }
    .second-col fieldset {
        margin-left: 0.3rem;
        margin-right: 0.5rem;
    }
    
    .page-addArticle {
        .form-block {
            padding: 0.5rem 0.9rem;
        }
        .field-header-right-section {    
            .dropdown-pane {
                width: 365px;
            }
        }
    }
    .field-msg-helper .msg-body {
        font-size: 0.8rem;
    }
}