.widget {
    font-weight: bold;
    margin-bottom: 5px;
    >a {
        border: 1px solid #fff;
        border-radius: 2px;
        box-sizing: border-box;
        color: $colorPrimary;
        display: block;
        padding: 5px 5px 5px 7px;
        width: 344px;
        /*height: 112px;*/

        >span {
            display: block;
            letter-spacing: 0.5px;
            line-height: 18px;
            padding: 7px 0 9px;
        }
        >span:hover {
            text-decoration: underline;
        }
    }
    .button {
        font-size: 0.813rem;//0.95rem;//1rem;
        font-weight: 900;
        padding: 0.9em 0 0.8em;//.35em 0 .3em;//0.65em 0 0.4em;
        text-transform: uppercase;
        width: 200px;
        margin-bottom: 0;
        line-height: 1.2;
    }
    img {
        width: 60px;
        /*float: left;*/
        margin-right: 15px; 
    }
}
.widget-header h3 {
    position: relative;
    line-height: 1.6;
    margin-top: -5px;
    margin-bottom: 0.4rem;
    font-size: 1.1em;
    font-weight: 800;
    text-transform: uppercase;
            
    .icon-youtube-light { 
        margin-bottom: 8px;
    }
}
.soc-bl a {
    display: inline-block;
    width: 38px; height: 38px;
    margin-right: 2px;
    text-decoration: none;
}

.side-widget {
    background-color: #fff;
    border: 1px solid $colorContrast;
    border-radius: 3px;
    font-size: 0.9rem;
    
    >a {
        width: auto;
        height: auto;
        padding: 2px 9px 9px 9px;
        /*margin-bottom: 5px;*/
        text-decoration: none;
        
        >span {
            letter-spacing: 0;
        }
        >span:hover {
            text-decoration: none;
        }
    }

    .widget-header {
        line-height: 1;
/*        margin-top: 0;
        margin-left: 60px;*/
        /*margin-top: 6px;*/
        margin-left: 34px;
        
        h3 {
            float: none;
            display: inline-block;
            color: $colorContrast;
            height: 26px;
            margin-top: 0;
            margin-bottom: 0;
        }
        .icon-youtube {
            height: 31px;
        }
        .name {
            display: inline-block;
            padding-top: 2px;
            font-size: 0.8rem;
        }
    }
    .button {
        width: 100%;
        /*margin-top: 0.5rem;*/
    }
    img, .img {
/*        width: 50px;
        height: 50px;*/
        width: 26px;
        height: 26px;
        margin-right: 10px;
        margin-top: 6px;
    }
    .img {
        display: block;
        float: left;
        background: url(/img/icons.png) no-repeat -64px -1787px;//-51px -1775px;
    }
    margin-bottom: $marginBlockDefault;
}
.contacts-widget {
    margin: 5px 25px 0 5px;
    min-width: 235px;
    
    .side-widget {
        border: 0;
        
        .img {
            margin-top: 0;
        }
        .widget-header {
            margin-top: 0;
        }
        .button {
            margin-top: .7rem;
            padding: 1em 0;
        }
    }
    .widget {
        margin-bottom: 0;
        
        >a {
            padding: 0;
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .widget-header h3 {
        .icon-youtube-light {   
            bottom: 1px;
            position: absolute;
            right: 21px;     
            margin-bottom: 0;
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .widget-header h3 {
        float: left;
        line-height: 1;
        margin-top: 0;
        /*margin-bottom: 0.2rem;*/
        
        .icon-youtube-light {
            bottom: -3px;
            position: absolute;
            right: -66px;
        }
    }
    h3 .icon-youtube-light {
        /*bottom: -4px;*/
        position: absolute;
        right: -66px;
    }
    .side-widget {
        margin-bottom: $marginBlock1200;
        .widget-header {
            .name {
                font-size: 0.9rem;
            }
        }
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .widget-header h3 {
        margin-bottom: 0.5rem;
    } 
}
