.header-mob-top {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background-color: $colorContrast;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.hmob-menu {
    width: 25px;
    
    button {
        border: 1px solid #fff;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
}
.hmob-search {
    position: relative;
    width: 40px;
    align-self: center; 
    
    .hmob-search-wrapper {
        position: relative;
        height: 24px;
        width: 100%;
        padding-top: 1px;
        &:after {
            content: " ";
            position: absolute;
            width: 19px; height: 19px; 
            left: 10px;
            background: url(/img/icons.png) no-repeat -79px -1693px;
        }
    }
    .search {
        margin: 0 auto;
        padding-left: 14px;
        width: 24px;
        height: 24px;
        border: 1px solid $colorPrimary;
        border-radius: 2px;
        background-color: $colorContrast;
        color: #fff;
        position: absolute;
        left: 0.5rem;
        &.expanded {
            width: 160px;
            padding-left: 24px;
            background-color: #fff;
            color: $colorContrast;
            font-size: 0.9rem;
        }
    }
/*    .icon-search {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -20px;
    }*/
}
.hmob-contacts {
    padding-left: 0.2rem;
    color: $colorPrimary;
    font-size: 0.85rem;
    line-height: 1;
    text-align: center;
    
    a {
        text-decoration: none;
    }
    span.data {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        background-color: #fff;
    }
    span {
        display: block;
    }
}
.hmob-cart {
    width: 70px;
    .icon-cart-mobile {
        vertical-align: middle;
        background: url(/img/icons.png) no-repeat scroll -6px -1689px;
        display: inline-block;
        height: 30px;
        width: 39px;
    }
    a {
        display: block;
        text-decoration: none;
        margin: 0 0.5rem;
    }
    .cart-qnt {
        font-size: 0.813rem;
        display: inline-block;
        vertical-align: top;
        line-height: 1;
        margin-left: -27px;
        position: absolute;
        background-color: white;
        padding: 2px 5px;
        border-radius: 10px;
    }
}
.hmob-currency {
    select {
        width: auto;
        height: 1.7rem;
        padding: 0.1rem 1.2rem 0.2rem 0.2rem;
        margin-bottom: 0;
        background-color: $colorPrimary;
        border-color: $colorPrimary;
        color: #fff;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");
        font: normal 600 1.1rem/1 "Open Sans Condensed";
        //line-height: 1;
        
        &:focus {
            border: 1px solid $colorPrimary;
            box-shadow: none;
        }
        &:hover {
            background-color: $colorPrimary;
            box-shadow: none;
        }
        option {
            background-color: #fff;
            &:focus {
                background-color: $colorPrimary;
                box-shadow: none;
            }
        }
    }
}
.hmob-langs {
    #menu-langs.menu a, #menu-langs.menu > li > span {
        padding: .15rem .25rem;
    }
}

.header-mob-logo {
    text-align: center;
    padding: 42px 2.5rem 4px;
    background-color: #fff;
}
.header-mob-lot-menu {
    display: flex;
    /*text-align: center;*/
    padding: 0.3rem 0.5rem 4px;
    background-color: #fff;
    justify-content: space-around;
    
    a:not(.button) {
        padding: 0 0 0 1rem;
        display: block;
    }
}
.page-home .header-mob-lot-menu {
    padding-top: 42px;
}
.header-mob-serv-menu {
    display: flex;
    padding: 0.2rem 0.5rem;
    background-color: #fff;
    justify-content: space-around;
    flex-wrap: wrap;
    
    a {
        padding: 0 0.2rem;
        display: inline-block;
        font-size: 0.8rem;
    }
}
.header-mob-serv-menu-icon {
    vertical-align: text-top;
    display: inline-block;
    background: url(https://tt-maximum.com/forum/index.php?action=dlattach;attach=6105;type=avatar) no-repeat 0 0;
    background-size: cover;
    width: 37px;
    height: 23px;
}

.header-mob-menu {
    background-color: $colorPrimary;
    
    .menu {
        li {
            width: 50%;
        }
        a {
            color: $colorMenu;
            text-transform: uppercase;
            text-align: center;
            /*padding: 1rem;*/
            padding: 1rem 0.9rem 0.8rem;
            border-bottom: 3px solid transparent;
            font-weight: 800;
        }
        a:hover, a.active {
            background-color: $colorPrimarySupport;
            border-bottom: 3px solid $colorActive;
        }
    }
}
