#loader-wrapper, #bg {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(66, 65, 65, 0.5);
}
#bg {
    z-index:9995;
}
#loader-wrapper {
    z-index:9997;
    .loader {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
        z-index: 9999;
    }
}
.loader {
    width: 80px;
    height: 80px;
    background: url(/img/ttm-loader.gif) no-repeat 0 0;
}

/*.loader {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.loader div {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: salmon;
  -webkit-animation: go 0.8s infinite alternate ease;
          animation: go 0.8s infinite alternate ease;
  box-shadow: 0 0 20px salmon;
}
.loader div:nth-child(1) {
  -webkit-animation-delay: -0.72s;
          animation-delay: -0.72s;
}
.loader div:nth-child(2) {
  -webkit-animation-delay: -0.64s;
          animation-delay: -0.64s;
}
.loader div:nth-child(3) {
  -webkit-animation-delay: -0.56s;
          animation-delay: -0.56s;
}
.loader div:nth-child(4) {
  -webkit-animation-delay: -0.48s;
          animation-delay: -0.48s;
}
.loader div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.loader div:nth-child(6) {
  -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
}
.loader div:nth-child(7) {
  -webkit-animation-delay: -0.24s;
          animation-delay: -0.24s;
}
.loader div:nth-child(8) {
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
}
.loader div:nth-child(9) {
  -webkit-animation-delay: -0.08s;
          animation-delay: -0.08s;
}
.loader div:nth-child(10) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

@-webkit-keyframes go {
  100% {
    background: transparent;
    -webkit-box-flex: 10;
            flex: 10;
    box-shadow: 0 0 0 transparent;
  }
}

@keyframes go {
  100% {
    background: transparent;
    -webkit-box-flex: 10;
        -ms-flex: 10;
            flex: 10;
    box-shadow: 0 0 0 transparent;
  }
}*/

// second
/*.loader {
  height: 4px;
  width: 200px;
  position: relative;
  overflow: hidden;
  background-color: $colorPrimary;
}
.loader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: $colorActive;
  animation: loading 2s linear infinite;
}

@keyframes loading {
    from {left: 0; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}*/

// first
/*#loader-wrapper {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    z-index: 9999;
}

.loader {
  position: absolute;
  opacity: .7;
}

.loader circle {
  animation: draw 2s infinite ease-in-out;
  transform-origin: center;
  transform: rotate(-90deg);
}

.loader-2 circle,
.loader-6 circle {
  animation-delay: 0.3s;
}

.loader-7 circle {
  animation-delay: 0.8s;
}

.loader-4 circle, 
.loader-8 circle {
  animation-delay: 1.2s;
}

.loader-3 {
  left: -150px;
  transform: rotateY(180deg);
}

.loader-6,
.loader-7,
.loader-8 {
  left: -150px;
  transform: rotateX(180deg) rotateY(180deg);
}

.loader-5 circle {
  opacity: .2;
}

@keyframes draw {
  50% {
    stroke-dashoffset: 0;
    transform: scale(.5);
  }
}*/