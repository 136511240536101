.menu-cat-support {
    padding: 8px $paddingBlockDefault;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 1px;
    
    a {
        //color: $colorFooter;
        
    }
    
    .menu {
        justify-content: space-between;
        a {
            color: $colorFooter;
            padding: 0;
            text-align: center;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        } 
/*        li {
            flex: 1 1 auto;
        }*/
        li:first-child a {
            padding-left: 0;
            text-align: left;
        } 
        li:last-child a {
            padding-right: 0;
            text-align: right;
        }
/*        li:nth-child(1) a::after, li:nth-child(2) a::after {
            position: absolute;
            color: $colorBorder;
            content: "|";
            display: inline-block;
            font-size: 0.8em;
            margin-left: 8px;//14px;
        }*/
        li:nth-child(3) a::after {
            position: absolute;
            color: $colorBorder;
            content: "|";
            display: inline-block;
            font-size: 0.8em;
            margin-left: 7px;//4px;
        }
        li:nth-child(4) {
            margin-left: 10px;
        }
    }
    .menu-cat-support-brand {
        margin-top: 12px;
        padding-top: 5px;
        border-top: 1px solid $colorBorder;
    }
}
.menu-cat-dropdown .menu-cat-support .menu {
    margin-top: 0.5rem;
}
@media screen and #{breakpoint(xlarge)} {
    .menu-cat-support {
        //padding: 12px $paddingBlock1200 8px;
        padding: 20px $paddingBlock1200 18px;
        font-size: 0.86rem;
        
        .menu {
/*            li:nth-child(1) a::after, li:nth-child(2) a::after {
                margin-left: 11px;//16px;
            }*/
            li:nth-child(3) a::after {
                margin-left: 9px;//6px;
            }
        }
    }
}
@media screen and #{breakpoint(xxlarge)} {
    .menu-cat-support {
        
        .menu {
/*            li:nth-child(1) a::after, li:nth-child(2) a::after {
                margin-left: 18px;//22px;
            }*/
            li:nth-child(3) a::after {
                margin-left: 14px;//6px;
            }
        }
    }
}