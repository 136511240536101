.search-bar {
    position: absolute;
    bottom: -41px;
    left: 230px;
    //width: 508px;
    /*width: 200px;*/
    width: calc(100% - 220px - 0.6rem);
    
    input {
        margin: 0;
        height: 1.85rem;//1.938rem;
        border: 0;
        box-shadow: none;
        font-style: italic;
        padding: 0.4rem 0.5rem 0.4rem 1.25rem;
    }
    button {
        position: absolute;
        right: 5px;
        top: 6px;
    }
    
    .search-hint {
        position: absolute;
        top: 29px;
        right: 0;
        padding: 0 1.5rem 1rem 1.5rem;
        visibility: hidden;
        font-size: 0.8rem;
        background: #fff;
        width: 50%;
        border: 1px solid $colorPrimary;
        border-top: none;
        z-index: 99;
    }
}

// header main bar ------------------------------------------------------------
.header-main {
    background-color: #fff;
    
    a {
        text-decoration: none;
    }
}
.hmc-logo {
    /*width: 220px;*/
    width: $widthColumnLeft;
    a {
        display: block;
        /*background: url(/img/ttm-logo.svg) no-repeat 0 0;*/
        /*text-align: justify;*/

        img {
            width: 100%;
        }
/*        span {
            display: block;
            color: $colorFooter;
            font-size: 0.9rem;
            font-weight: 600;
        }*/
    }
}
.header-main-col-wrapper {
    display: flex;
    /*flex-wrap: wrap;*/
    justify-content: space-between;
    align-items: center;
    height: 102px;
    
    .hmc-contacts {
        width: 125px;
        font-size: 0.9rem;
        color: $colorFooter;
        margin-left: get-list-value($headerWorkAreaMargin, medium);
        
        >div {
            display: block;
        }
        .hmc-contacts-data {
            line-height: 1.2;
            a {
                color: $colorFooter;
            }
        }
        .hmc-contacts-phone {
            font-weight: 800;
            background: none;
            margin-bottom: 0.1rem;
                
            >span {
                display: inline-block;
                vertical-align: top;
            }
            .icon {
                display: none;
            }
            .data.third {
                display: none;
            }
        }
        .hmc-contacts-email, .hmc-contacts-skype, .hmc-contacts-ims {
            display: none;
            background: none;
            line-height: 1.5;
        }
        .hmc-contacts-ims {
            .contact-value {
                font-weight: bold;
            }
        }
        .hmc-contacts-btn {
            display: none;
            a {
                text-decoration: underline;
            }
            a:hover {
                text-decoration: none;
            }
        }
        .hmc-contacts-btn768 {
            width: 125px;
            text-align: center;
            margin-top: 6px;
            box-sizing: border-box;
        
            .button {
                margin: 0;
                padding: 0.2em;
                background: transparent;
                border-color: $colorPrimary;
                color: $colorPrimary;
                font-size: 0.9rem;
                font-weight: 600;
                width: 100%;
                text-transform: uppercase;
            }
            .button:hover, .button:focus {
                border-color: $colorFooter;
                color: $colorFooter;
            }
        }
        .hmc-contacts-btn-add-text {
            display: none;
        }
/*        .hmc-contacts-btn {
            width: 125px;
            text-align: center;
            margin-top: 6px;
            box-sizing: border-box;
        
            .button {
                margin: 0;
                padding: 0.2em;
                background: transparent;
                border-color: $colorPrimary;
                color: $colorPrimary;
                font-size: 0.9rem;
                font-weight: 600;
                width: 100%;
                text-transform: uppercase;
            }
            .button:hover, .button:focus {
                border-color: $colorFooter;
                color: $colorFooter;
            }
            span {
                display: block;
            }
            span:last-child {
                display: none;
            }
        }*/
    }

    .hmc-currency {
        width: 173px;
        text-align: center;
        
        >span {
            display: none;//block;
            font-size: 0.9rem;
            font-weight: 800;
            color: $colorFooter;
        }
        >ul {
            display: block;
            margin: 2px 0 0 0;
            
            li {
                display: inline-block;
            
                a {
                    /*display: block;*/
                    font: 400 1.3rem/1 "Open Sans Condensed", sans-serif;
                    color: $colorFooter;
                    background-color: #fff;
                    border: 1px solid $colorPrimary;
                    margin: 0;
/*                    padding: 0.55em 0.35em;
                    width: 45px;*/
                    padding: .50em .22em;
                    width: 40px;
                }
                a:hover, a.active {
                    background-color: $colorPrimary;
                    color: #fff;
                }
            }
        }
    }
    .hmc-cart {
        width: 188px;
        text-align: right;
        
        a.button {
            padding: 0.5em 0;//1em;
            margin: 0;
            width: 140px;
            
            .hmc-cart-qnt {
                display: block;
                font-size: 0.9rem;
                font-weight: bold;//600;
            }
            .hmc-cart-sum {
                display: block;
                font-size: 0.76rem;
                font-weight: 600;
                text-transform: lowercase;
            }
            &.cart-is-empty {
                padding: 0.9em 1em 0.75em;
                
                .hmc-cart-qnt {
                    font-size: 1rem;
                }
            }
        }
        .icon-cart {
            vertical-align: middle;
            /*margin-right: 0;*/
            background: url(/img/icons.png) no-repeat scroll -6px -114px;
            display: inline-block;
            height: 34px;
            width: 39px;
        }
    }
}
/*@media screen and #{breakpoint(large)} {
    .header-main-col-wrapper {
        .hmc-contacts {
            width: 349px;
            margin-left: 40px;
            
            .hmc-contacts-data {
                width: 146px;
            }
            .hmc-contacts-btn {
                width: 129px;
            }   
        }
        .hmc-cart {
            width: 250px;
            .icon-cart {
                display: none;
            }
        }
    }
}*/
/*@media screen and #{breakpoint(medium)} {
    .search-bar {
        width: calc(100% - 220px - 0.6rem);
    }
}*/
@media screen and #{breakpoint(large)} {
    .search-bar {
        position: relative;
        margin-left: 6px;
        bottom: auto;
        left: 0;
        width: auto;
        
        input {
            padding-left: 0.5rem;
        }
    }
    .hmc-logo {
        width: 220px;//248px;//$widthColumnLeft1200;
        /*flex-basis: $widthColumnLeft1200;*/
    }
    .header-main-col-wrapper {
        
        .hmc-contacts {
            width: 336px;//390px;//349px;
            margin-left: 30px;//get-list-value($headerWorkAreaMargin, xlarge);
            line-height: 1.1;
            
            .hmc-contacts-data {
                width: 100%;
            }
            .hmc-contacts-phone {
                display: block;
                
                .icon {
                    display: inline-block;
                }
                .data.first::after {
                    content: ',';
                }
                .data {
                    width: 135px;
                }
/*                .data.second {
                    padding-left: 20px;
                }*/
            }
            .hmc-contacts-ims {
                display: inline-block;
                .contact-value {
                    padding-left: 3px;
                }
            }
            .hmc-contacts-skype {
                display: inline-block;
                .contact-value {
                    padding-left: 3px;
                }
            }
            .hmc-contacts-email, .hmc-contacts-skype {
                margin-left: 15px;
            }
/*            .hmc-contacts-data {
                width: 330px;//146px;//165px;
            }*/
            >div {
                display: inline-block;
                vertical-align: middle;
            }
            .hmc-contacts-btn {
                display: block;
                margin-top: 0.3rem;
            }
            .hmc-contacts-btn768 {
                display: none;
            }
/*            .hmc-contacts-btn {
                width: 129px;//145px;
                margin-top: 0;
                .button {
                    padding: 0.45em;
                    line-height: 1.1;
                }
                span:last-child {
                    display: block;
                }
            }*/
        }
        
        .hmc-currency {
            width: 180px;//192px;//290px;
            >ul {
                display: inline-block;
                margin: 0;
            }
            >span {
                //display: none;
/*                display: inline-block;
                line-height: 1.1;
                margin-right: 0.3em;
                text-align: left;
                vertical-align: middle;
                width: 75px;*/
            }
            border-right: 1px solid #EBEBEB;
            border-left: 1px solid #EBEBEB;
        }
        .hmc-cart {
            width: 212px;//245px;//250px;//275px;
            .icon-cart {
                margin-right: 4px;
                width: 45px;
                height: 36px;
                background: url(/img/icons.png) no-repeat -5px -73px;
                display: none;
            }
            a.button {
                width: 200px;
            }
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .hmc-logo {
        width: 270px;
        /*flex-basis: $widthColumnLeft1600;*/
    }
    .header-main-col-wrapper {
        .hmc-contacts {
            width: 350px;//383px;
            margin-left: 46px;
            /*width: 349px;*/
            /*margin-left: 46px;*/
            
/*            .hmc-contacts-data {
                width: 350px;//340px;//165px;
                
            }*/
/*            .hmc-contacts-btn {
                width: 145px;
            }*/
/*            .hmc-contacts-phone {
                .icon {
                    display: inline-block;
                }
            }*/
            .hmc-contacts-ims {
                display: inline-block;
            }
            .hmc-contacts-skype {
                display: inline-block;
            }
/*            .hmc-contacts-phone .data.second {
                padding-left: 20px;
            }*/
        }
        .hmc-currency {
            width: 290px;//282px;
                
            >ul li a {
                padding: 0.55em 0.35em;
                width: 45px;
            }
            
             >span {
                display: inline-block;
                line-height: 1.1;
                margin-right: 0.3em;
                text-align: left;
                vertical-align: middle;
                width: 75px;
            }
        }
        .hmc-cart {
            width: 275px;
            .icon-cart {
                display: inline-block;
            }
        }
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .hmc-logo {
        width: $widthColumnLeft1600;
        /*flex-basis: $widthColumnLeft1600;*/
    }
    .header-main-col-wrapper {
        
        .hmc-contacts {
            width: 540px;//520px;//510px;
            margin-left: get-list-value($headerWorkAreaMargin, xxxlarge);
            
            .hmc-contacts-data {
                //width: 500px;//325px;
                font-size: 0.9rem;
                line-height: 1.5;
            }
            .hmc-contacts-phone {
                >span {
                    vertical-align: middle;
                }
/*                .data {
                    width: auto;
                }*/
                .data.second {
                    padding-left: 0;
                }
                .data.second::after {
                    content: ',';
                }
                .data.third {
                    display: inline-block;
                }
            }
            .hmc-contacts-ims {
                display: inline-block;
            }
            .hmc-contacts-email {
                display: inline-block;
            }
            .hmc-contacts-btn-add-text {
                display: inline;
            }
        }
        .hmc-currency {
            width: 350px;//366px;//376px;
            
            >span {
                text-align: right;
                width: auto;
            }
        }
        .hmc-cart {
            width: 285px;//296px;
            a.button {
                width: 200px;
            }
        }
    }
}

