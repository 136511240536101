
h1, .block-title {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: $colorFooter;
}

h4 {
    font-size: 1.063rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

a:focus {
    outline: none;
}

.inbl {
    display: inline-block;
}
.pseudo-table {
    display: table;
}
.pseudo-table-row {
    display: table-row;
}
.pseudo-table-cell {
    display: table-cell;
}

.add-top-margin {
    padding-top: 0.8rem;
}

.parenthesis {
    position : relative;
    font-size : 88%; 
    top : -.15em;
}

.block {
    @include block;
    padding: 0.5em;
}
/*.block-padding {
    padding: 0.25em;
}*/
.block-header {
    display: flex;
    justify-content: space-between;
    
    .block-link {
        text-align: right;
        font-size: 0.9em;
        line-height: 1.3;
        padding-top: 4px;
        
/*        &::after {
            content: ' ';
            width: 24px;
            height: 24px;
            background: url(/img/icons.png) no-repeat -85px -620px;
        }*/
    }
}
.block-header-rblock {
    line-height: 1.2;
    /*margin-top: 0.2rem;*/
    /*text-align: right;*/
}
.block-title {
/*    margin-top: 1.8rem;*/
/*    margin-bottom: 0;
    line-height: 1.3;*/
}
/*.block-page-section {
    margin-top: 0.625rem;
    &:first-child {
        margin-top: 0;
    }
}*/

.slide-grid {
    position: relative;
}
/*.slide-grid-movable {
    overflow: hidden;
}*/
.slide-grid .grid-x {
    @include xy-grid('horizontal', false);    
    /*transition: transform 0.5s;*/
    .cell {
        display: none;
        transition: width 0.7s ease-in-out;
        /*transition: display 0.5s;*/
        overflow: hidden;
        &:hover {
            overflow: visible;
        }
        >div {
            width: 198px;
        }
        >div.obj-box-expanded-part {
            width: 216px;
        }
    }
}
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {opacity: .3} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .3} 
  to {opacity: 1}
}


/*.in-row-space {
    display: flex;
    justify-content: space-between;
}*/
.list-container {
    list-style-type: none;
}

ol.ol-bold-standard {
    list-style: none;
    margin-left: 0;
    
    >li {
        counter-increment: item;
        margin-bottom: 5px;
    }
    >li:before {
        margin-right: 0.2rem;
        content: counter(item) ".";
        font-weight: bold;
        /*background: lightblue;*/
        /*border-radius: 100%;*/
        /*color: white;*/
        /*width: 1.2em;*/
        /*text-align: center;*/
        /*display: inline-block;*/
    }
    ul {
        margin-top: 0.3rem;
        >li {
            margin-top: 0.15rem;
        }
    }
}
 

.status-book {
    color: $colorActiveSupport !important;
}
.status-0 {color: $colorPrimary;}
.status-2, .status-5 {color: $colorActive;}
.status-1, .status-3, .status-4, .status-6 {color: $colorRemark;}
.rem {
    color: $colorRemark;
    font-size: 0.813rem;
}
.rem-big {
    color: $colorRemark;
    font-size: 0.9rem;
}
.rem-ital {
    color: $colorRemark;
    font-size: 0.813rem;
    font-style: italic;
}
.rem-unsized {
    color: $colorRemark;
}
.rem-dark {
    font-size: 0.813rem;
}
.subtitle {
    font-size: 0.938rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0; // 0.2rem
}
.subtitle-highlighted {
    color: $colorActive;
}
.text {
    font-size: 0.9rem;
    font-weight: normal;
}

.text-pay-details-label {
    display: block;
    padding-top: 0.06rem;
    font-size: 0.813rem;
    line-height: 1.25;
}
.text-pay-details-number {
    font-size: 0.96rem;
    font-weight: bold;
    line-height: 1.3;
}
.text-pay-details-note {
    font-size: 0.813rem;
    font-weight: bold;
}

a.link-active {
    color: $colorActiveSupport;
}

.o-label, a.o-label, .g-label {
    padding: 0 7px;
    border-radius: 2px;
    font-size: 0.7rem;
    text-decoration: none;
}
.o-label {
    color: #fff !important;
    background-color: $colorActiveSupport;
}
a.o-label:hover {
    background-color: $colorActive;
}
.g-label {
    background-color: $colorBorder;
    color: $colorContrast;
}
.highlight {
    color: $colorActiveSupport;
}
.semibold {
    font-weight: 600;
}
.text-plaque {
    line-height: 1.7;
    font-weight: bold;
}
.text-brief {
    margin: 1.6rem 0;
}
.text-button {
    font-size: 0.875rem;
}
.odd {
    background-color: $colorBg;
}

.msg {
    border: 1px solid $colorContrast;
    border-radius: 2px;
    padding: 1rem 1.2rem;
    font-size: 0.9rem;
    margin-bottom: 1rem;
/*    display: flex;
    align-items: center;*/
    
    >span {
        display: block;
    }
    .icon {
/*        flex-shrink: 0;
        align-self: flex-start;*/
        float: left;
        margin-right: 1rem;
    }
    .msg-body {
/*        padding-left: 1rem;
        flex-grow: 1;*/
        margin-top: -5px;
        font-size: 0.813rem;
    }
    
    .grid-margin-y {
        margin-top: -0.2rem;
        margin-bottom: -0.2rem;
    }
    .grid-margin-y > .cell {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
    }
    
    &.msg-type-error {
        border: 1px solid $colorActiveSupport;
        
        li::before {
            color: $colorActiveSupport !important;
        }
    }
    &.msg-type-ok {
        border: 1px solid $colorPrimary;
        color: $colorPrimary;
    }
}
.msg.msg-type-error, .msg.msg-type-ok {
    display: flex;
    margin-top: 1rem;
    
    .icon {
        float: none;
        flex: 0 0 auto;
    }
    
    .msg-body {
        /*margin-left: 3rem;*/
        flex: 1 0 auto;
        align-self: center;
    }
    ul {
        list-style-type: none;
        margin: 0;

        li::before {
            content: '*';
            margin-right: 0.3rem;
            color: $colorPrimary;
        }
    }
}

.form-inline-row {
    display: flex;
    align-items: center;

    label {
        display: inline
    }
}

// все переключатели режимов на страницах, например выбор кол-ва элементов на странице для пейджера
.switcher-form {
    label {
        font-size: 0.8em;
        line-height: 1.7;
        padding-right: 0.5rem;
    }
    select {
        height: 1.6rem;
        margin: 0;
        padding: 0 1.5rem 0 0.5rem;
        width: auto;
        font-size: 0.8em;
    }
}

table.reset-table {
    width: auto;
    caption, tbody, tfoot, thead, tr, th, td {
        margin: 0;
        /*padding: 0;*/
        border: 0;
        outline: 0;
        /*font-size: 100%;*/
        /*vertical-align: baseline;*/
        background: transparent;
    }
}
table.table-info {
    tbody th, tbody td {
        padding: 0.5rem 0.625rem 0.5rem;
    }
    th {
        background-color: $colorContrast;
        color: #fff;
        text-transform: uppercase;
        font-size: 0.86rem;
    }
    &.all-center {
        td, th {
            text-align: center;
        }
    }
}

.breadcrumbs {
    margin: 0 0 0.8rem 0;
}

.bn-link {
    span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.1;
        font-size: 0.813rem;
        font-weight: 600;
        text-decoration: none;
        
        &:not(.icon-dlv) {
            text-decoration: underline;
        }
        &:not(.icon-dlv):hover {
            text-decoration: none;
        }
    }
    display: inline-block;
    margin-bottom: 0.5rem;
}

.search-result-cond {
    margin: 1rem 0;
    font-size: 0.9rem;
}
.search-result-value {
    font-style: italic;
    font-weight: bold;
}

/*select:-moz-focusring { // это попытка убрать outline из select в FF - некрасиво
    color: transparent;
    text-shadow: 0 0 0 #000;
}*/
/*progress
progress::-moz-progress-bar,
progress::-webkit-progress-value {
    display: block;
    width: 100%;
    height: 5px;
    margin-bottom: 1rem;
    appearance: none;
    border-radius: 0;
    border: 0;
    background: $colorBorder;
    color: $colorPrimary;
}*/

/*.expandable-control a {
}*/
body.loading {
   cursor: wait;
}
.hide {
    display: none;
}

@media screen and #{breakpoint(medium)} {
    .block{
        padding: 0.65em 1.5rem; //$paddingWorkAreaDefault 1.5rem 
    }
    .bn-link {
        span {
            font-size: 0.9rem;
        }
    }
    .block-header {
        margin-bottom: 0.8rem;
        margin-top: 1.8rem;
    }
    .block-header-rblock {
        margin-top: 0.2rem;
    }
    h1, .block-title {
        font-size: 1.35rem;
    }
    .block-title {
        margin-bottom: 0;
        line-height: 1.3;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .block {
        padding: 0.65em $paddingWorkArea1200;//1.5em $paddingWorkArea1200;
    }
    .switcher-form {
        select, a.select-emul {
            font-size: 0.9rem;
            /*padding: 0 1.5rem 0 0.5rem;*/
        }
        label {
            font-size: 0.9rem;
        }
    }
    .msg .msg-body {
        font-size: 0.9rem;
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .block {
        padding: 1.5em $paddingWorkArea1600;
    }
}