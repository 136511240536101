.pager-pages {
    text-align: center;
    margin-bottom: 0.7rem;
    
    a, span, strong {
        padding: 0.2rem 0rem;
        margin-left: 1px;
        color: #000;
        display: inline-block;
        width: 28px;
        font-size: 0.9rem;
        text-align: center;
        border-radius: 2px;
    }
    a {
        background-color: $colorBorder;
        text-decoration: none;
            
        &:hover {
            background-color: $colorPrimary;
            color: #fff;
        }
    }
    span.pageCurrent, strong {
        background-color: $colorFooter;
        color: #fff;
    }
    .pageLinkPrev, .pageLinkNext {
        background-color: transparent;
        height: 30px;
        &:hover {
            background-color: transparent;
        }
        &::before {
            content: "";
            display: inline-block;
            width: 16px; height: 16px;
            background-image: url(/img/icons.png);
            background-repeat: no-repeat;
        }
    }
    .pageLinkPrev::before {
        background-position: -84px -670px;
    }
    .pageLinkNext::before {
        background-position: -84px -695px;
    }
}
.pager-bottom {
    /*position: relative;*/
    /*min-height: 28px;*/
    margin-top: 0.8rem;
    overflow: hidden;

    .switcher-form { // выбор кол-ва элементов на странице в нижнем переключателе
        margin: 0.3rem auto 0.8rem;
    }
    .pager-form .form-inline-row {
        justify-content: center;
    }
}
@media screen and #{breakpoint(medium)} {
    .pager-pages {
        a, span, strong {
            width: 30px;
        }
    }
    .pager-top {
        margin-bottom: 0.8rem;
    }
    .pager-bottom {
/*        .pager-pages {
            float: left;
            margin-bottom: 5px;
            margin-right: -161px;
            width: 100%;
        }*/
        .switcher-form {
            /*clear: left;*/            
            /*width: 161px;*/
            /*margin: 0;*/
        }
        .pager-form .form-inline-row {
            justify-content: flex-end;
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .pager-bottom {
        .pager-pages {
            margin-right: -180px;
            float: left;
            margin-bottom: 5px;
            /*margin-right: -161px;*/
            width: 100%;
        }
        .switcher-form {
            clear: none;
            float: right;
            width: 180px;
            margin-top: 2px;
        }
    }
}