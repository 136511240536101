.product-review-list {
    .comm-inner {
        display: flex;
    }
    .comm-img {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        text-align: center;
    }
    .comm-info {
        flex-grow: 1;
        padding-left: 0.6rem;
    }
    .comm-name a {
        font-size: 0.8rem;
    }
}

.comm-title {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    border-bottom: 1px solid $colorBorder;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.comm {//:not(.page-home.mobile)
    /*position: relative;*/
    margin: 0 -0.25rem;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    border-bottom: 1px solid $colorBorder;
    
    &.like .comm-name a::before, &.dislike .comm-name a::before {
        content: " ";
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 7px;
        vertical-align: sub;
    }
    &.like .comm-name a::before {
        background: url(/img/like.png) no-repeat 0 0;
    }
    &.dislike .comm-name a::before {
        background: url(/img/dislike.png) no-repeat 0 0;
    }
    .label-new {
        font-size: 0.7rem;
        background-color: $colorActive;
        color: #fff;
        padding: 0 0.3rem;
    }
    .comm-link-viewed {
        /*position: absolute;*/
        display: inline-block;
/*        top: 0;
        right: 10px;*/
        padding-left: 1rem;
        font-size: 0.9rem; font-weight: normal;
        color: $colorActive;
    }
}
.comm.comm-add {
    border-bottom: 0;
}
.mobile .comm {
    margin: 0;
}
.mobile.page-getProduct .comm {
    border-bottom: 0;
    margin-bottom: 0.5rem;
}
.mobile.page-comments .comm{
    padding: 0.75rem 0 0.5rem;
}
.comm-obj {
    margin-bottom: 0.2rem;
    
    a {
        font-size: 1rem;
        font-weight: bold;
        color: $colorContrast;
    }
}
.comm-name {
    a {
        font-size: 1rem;
        font-weight: bold;
        color: $colorContrast;
    }
}
.comm-date {
    color: $colorRemark;
    font-size: 0.8rem; 
    padding-left: 0.3rem;
}
.comm-body {
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
    border: 1px solid $colorBorder;
    border-radius: 3px;
}
.sh .comm-body {
    border: 0;
}
.comm-tools {
    position: absolute;
    right: 0;
    top: -1.9rem;
    font-weight: 600;
}
.comm-text {
    padding: 0.3rem 0.5rem;
    word-break: break-word;
    
    p {
        margin: 0;
    }
}
.comm-answer {
    margin-top: 0.2rem;
}
.comm-note {
    text-align: center;
    margin-top: 0.75rem;
    font-size: 0.875rem;
    color: $colorRemark;
}
.comm-add-text {
    font-size: 0.8rem;
    p {
        margin-bottom: 0.3rem;
    }
}
.comm-add-field {
    .buttons-panel {
        margin-top: 0.3rem;
    }
}

.quoteAuthor, .quoteText {
    background-color: $colorBg;
    font-size: 0.8rem;
}
.quoteAuthor {
    padding: 0.3rem 0.7rem 0;
    border-radius: 2px 2px 0 0;
    font-weight: 600;
    margin-top: 0.3rem;
    
    span {
        font-weight: normal;
    }
}
.quoteText {
    padding: 0.1rem 0.7rem 0.3rem;
    border-radius: 0 0 2px 2px;
    margin-bottom: 0.3rem;
    
    .quoteAuthor, .quoteText {
        border-width: 1px;
        border-style: solid;
        border-color: $colorBorder;
    }
    .quoteAuthor {
        border-bottom: 0;
    }
    .quoteText {
        border-top: 0;
    }
}

#popup-tools {
    //border: 1px solid #345213;
    border-radius: 3px 3px 3px 3px;
    display: none;
    font-size: 0.8rem;
    padding: 0;
    position: absolute;
    width: auto;
    z-index: 100;
}
#popup-tools a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 5px;
    background-color: $colorActive;
    opacity: 0.8;
}
#popup-tools a:hover {
    text-decoration: underline;
    opacity: 1;
    background-color: $colorActiveSupport;
}

@media screen and #{breakpoint(medium)} {
    .comm{
        margin: 0 -1.5rem;//-2.125rem;
        padding: 1.563em 2.125rem 1.313em;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .comm {
        margin: 0 -1.625rem;
        padding: 1.563em 1.625rem 1.313em;
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .comm {
        margin: 0 -4rem;
        padding: 1.563em 4rem 1.313em;
    }
}