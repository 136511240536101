.dropdown-link {
    position: relative;
    display: block;
    cursor: pointer;
    border: 1px solid $colorBorder;
    font-size: 0.96rem;
    font-weight: 600;
    padding: 0.3rem 0.5rem;
    border-radius: 2px;
    margin-top: 0.3rem;
    margin-bottom: -1px;
    text-transform: uppercase;

    &:after {
        content: " ";
        position: absolute;
        display: block;
        width: 19px; height: 19px;
        right: 5px;
        top: 50%;
        margin-top: -10px;
        background: url(/img/icons.png) no-repeat -83px -883px;
    }
}

.flt-box {
    .dropdown-link {
        font-size: .9rem;
        font-weight: 800;

        &:after {
            background: none;
            content: "+";
            color: $colorContrast;
            font-weight: bold;
            font-size: 1.3rem;
            line-height: 1.3rem;
            text-align: center;
            margin-top: -11px;
        }
        &.is-opened:after {
            content: "-";
            font-size: 2rem;
            font-weight: normal;
        }
    }
}

.dropdown-link-gr {
    
    &:after {
        display: inline-block;
        content: " ";
        vertical-align: middle;
        width: 16px;
        height: 17px;
        margin-left: 3px;
        background-image: url(/img/icons.png);
        background-repeat: no-repeat;
        background-position: -63px -914px;
    }
}