.footer {
    font-size: 0.9rem;
    /*padding-top: 1rem;*/
    
    a{
        text-decoration: none;
    }
    .sub-header {
        display: block;
        font-weight: bold;
        text-decoration: underline;
    }
    .rem {
        display: block;
        font-size: 0.7rem;
        line-height: 0.86rem;
    }
    h3 {
        font-size: 1em;
        font-weight: 800;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 1.2rem;
        
        &.dropdown-link {
            border: 1px solid #fff;
            font-weight: normal;
            padding: 0.35rem 0.8rem;
            border-radius: 3px;
            margin-bottom: 0;

            &:after {
                background: url(/img/icons.png) no-repeat scroll -38px -1589px;
            }
        }
        &.dropdown-link.is-opened {
            &:after {
                background: url(/img/icons.png) no-repeat scroll -79px -1589px;
            }
        }
    }
    ul {
        list-style: none;
        margin: 0;
        a {
            color: #fff;
        }
        a:hover {
            color: $colorPrimarySupport;
        }
    }
    
    .fm-1 {
        .inbl {
            vertical-align: top;
        }
        .fm1-subheader-2 {
            margin-top: 12px;
        }
    }
    .fm-3 img {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }
    .fm-4, .fm-6 {
        display: none;
    }
    .fm-5-1.widget-header .rem {
        margin-top: -10px;
        margin-bottom: 8px;
        color: #fff;
    }
    .fm-5-2 {
        margin-bottom: 0.7rem;
        
        .widget {
            > a {
                padding: 5px 5px 5px 5px;
                width: 100%;
                
                &:hover {
                    text-decoration: none;
                }
            
                > span {
                    font-size: 0.8rem;
                    display: inline-block;
                    letter-spacing: 0;
                    
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            img {
                margin-right: 5px;
            }
            .button {
                width: 220px;//185px;
            }
        }
    }
    .fm-5-3 {
        /*margin-bottom: 0.7rem;*/
        display: flex;
        flex-direction: column; 
        justify-content: flex-start;
/*        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;*/
    
/*        h3 {
            display: none;
        }*/
    }

    .footer-bottom {
        color: #fff;
        background-color: $colorPrimary;
        margin-top: 0.5em;
/*        padding-bottom: 0.5rem;*/
    }
    .footer-logo {
        vertical-align: middle;
        line-height: 1.2;
        font-size: 0.8rem;
        font-weight: 600;
        
        img {
            margin: 8px 20px 8px 0;
        }
        >* {
            display: block;
        }
    }
    .footer-contacts {
/*        >div {
            display: inline-block;
            vertical-align: middle;
        }*/
        .button {
            background-color: transparent;
            border: 1px solid #fff;
            margin-bottom: 0;
            padding: 0.15em 0.75em;
            text-transform: uppercase;
            padding: 0.25em 0.75em;
            font-size: 0.9rem;
        }
        .button:hover {
            color: $colorFooter;
            border-color: $colorFooter;
        }
        a {
            color: #fff;
        }

        .icon {
            display: none;
        }
        .data {
            /*font-weight: bold;*/
            display: block;
            line-height: 1.2;
            font-size: 0.813rem;
        }           
        .footer-contacts-skype {
            display: none;
        }
    }
    .fm-version-button {
        display: block;
        width: 135px;//50%;
        max-width: 155px;
        color: $colorPrimary;
        border: 1px solid $colorPrimary;
        font-size: 0.85rem;
        font-weight: bold;
        line-height: 1.2;
        text-transform: uppercase;
        padding: 0.15rem 0rem;
        text-align: center;
        border-radius: 2px;
    }
    .soc-bl .fm-version-button {
        width: 155px;
        padding: 0.05rem 0.5rem;
        margin-top: 0.6rem;
        margin-bottom: 0.5rem;
    }
}

/*@media screen and #{breakpoint(medium)} {*/
    .footer {
        padding-top: 2.5em;
        
        .footer-logo {
            img {
                margin: 7px 6px 8px 0;
            }
        }
/*        .fm-content {
            padding: 0;
            border: 0;
            margin-top: 0;
        }*/
        .footer-bottom {
            padding-bottom: 0;
        }
        .footer-contacts {
            /*text-align: right;*/
            /*padding-right: 15rem;*/
        }
    }
    
    .desktop {
        .footer-inner-wrapper {
            display: flex;
            flex-wrap: wrap;

            >div {
                flex: 0 1 auto;
                margin-bottom: 1.5em;
            }
            .fm-1 {
                width: 257px;//265px;
            }
            .fm-2 {
                width: 240px;//246px;
            }
            .fm-3 {
                width: 223px;//226px;

                img {
                    margin-top: 34px;//16px;
                    margin-bottom: 0;
                }
            }
    /*        .fm-4 {
                display: none;
            }*/
            .fm-5 {
                display: flex;
                flex-wrap: wrap;

                .fm-5-1 {
                    width: 115px; 

                    &.widget-header .rem {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
                .fm-5-2 {
                    width: 387px;//401px;
                    margin-bottom: 0;

                    .widget {
                        > a {
                            padding: 5px 5px 5px 10px;
                            width: 344px;
                            overflow: hidden;

/*                            > span {
                                font-size: 0.9rem;
                            }*/
                        }
                        img {
                            margin-right: 15px;
                        }
                        .button {
                            width: 245px;
                            margin: 7px 0 7px 0;
                        }
                    }
                }
                .fm-5-3 {
                    width: 226px;
                    margin-bottom: 0rem;

                    h3 {
                        display: block;
                        margin-bottom: 0.75rem;
                    }
                }
            }
    /*        .fm-widget-header h3 {
                position: relative;
                line-height: 1.6;
                margin-top: -5px;
                margin-bottom: 0.4rem;
            }*/
        }
        
        .footer-contacts {
            text-align: right;
                        
            .data {
                display: none;
                font-weight: bold;
                font-size: 0.9rem;
            }
            .footer-contacts-btn {
                margin: 0;
            }
            .button {
                padding: 0.3em 0.5em;
                margin-left: 5px;
            }
            >div {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .footer-logo {
            >* {
                display: inline-block;
            }
        }
    }
/*}*/

// =============================================================================
.mobile {
    .footer {
        padding-top: 1rem;
        
        .fm-content {
            padding: 0.8rem 1rem 0.8rem 2rem;
            border: 1px solid #fff;
            border-radius: 4px;
            margin-top: -1px;
        }
        .footer-contacts {
            >div.footer-contacts-skype {
                display: none;
            }
            .footer-contacts-btn {
                margin: 8px 0;
            }
        }
        .footer-bottom {
            padding-bottom: 0.5rem;
        }
        .fm-5-3 {
            margin-bottom: 0.7rem;

            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            h3 {
                display: none;
            }
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .footer {
        .fm-5-3 {
/*            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;*/
            /*align-items: center;*/

            h3, .soc-bl {
                flex: 0 1 auto;
            }
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .footer {
/*        .footer-logo {
            font-size: 0.9rem;
            font-weight: normal;
        }*/
        .footer-inner-wrapper {
            .fm-1 {
                width: 315px;
            }
            .fm-2 {
                width: 254px;
            }
            .fm-3 {
                width: 310px;
            }
            .fm-5 {
                width: 346px;
                align-content: flex-start;
                
                .rem {
                    float: right;
                    padding-right: 18px;
                    color: $colorRemark;
                }
                .fm-5-1 {
                    width: 346px;
                }
                .fm-5-2 {
                    margin-top: 0.5rem;//0.25rem;
                }
                .fm-5-3 {
                    width: 346px;
                    margin-top: 0.2rem;
                    
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
/*                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    align-items: center;*/
                    
                    h3 {
                        margin-bottom: 0rem;
                        flex: 0 1 152px;
                        text-align: right;
                        align-self: flex-start;
                    }
                    .soc-bl {
                        flex: 0 1 178px;
                        text-align: right;
                    }
                }
            }
        }
/*        .fm-widget-header h3 {
            float: left;
            line-height: 1;
            margin-top: 0;
            margin-bottom: 0.2rem;
        }*/
        .footer-contacts {
            /*padding-right: 16rem;*/
            
            >div {
                margin-left: 20px;
            }
            .data, .footer-contacts-skype, .icon {
                display: inline-block;
            }
            .data.first::after {
                content: ', ';
            }
            .footer-contacts-skype {
                margin-right: 1rem;
            }
/*            .button {
                margin-left: 5px;
            }*/
        }
    }
}
@media screen and #{breakpoint(xxxlarge)} {
    .footer {
        .footer-logo {
            img {
                padding-right: 20px;
            }
        }
        .footer-inner-wrapper {
            .fm-1 {
                width: 322px;
            }
            .fm-2 {
                width: 230px;//250px;
            }
            .fm-3 {
                width: 280px;//320px;//274px;
            }
/*            .fm-4 {
                display: block;
                width: 368px;
                
                .widget {
                    margin-top: 1px;
                }
            }*/
            .fm-5 {
                width: 408px;//345px;
                .fm-5-2 {
                    margin-top: 1rem;
                }
                .fm-5-3 {
                    display: none;
                }
            }
            .fm-6 {
                width: 320px;
                display: block;
                
                .fm-6-inner {
                    /*float: right;*/
                }
                .soc-bl {
                    display: flex;
                    padding-top: 0.25rem;
                    
                    a {
                        display: block;
                        width: 100%;
                        /*float: left;*/
                        margin-bottom: 0.4rem;
                        color: #fff;
                        
                        span.icon {
                            width: 38px; height: 38px;
                            margin-right: 0.7rem;
                        }
                    }
                    .soc-bl-inner:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }    
}

