.article {
    margin-bottom: 1rem;
}
.article-header {
    margin: 0.5rem 0 0.3rem;
}
.article-subheader {
    text-transform: none;
    padding-top: 1rem;
}
.article-date {
    float: left;
    font-size: 0.9rem;
    font-weight: bold;
    width: 100px;
}
.article-author {
    /*float: right;*/
    color: $colorRemark;
    margin-left: 100px;
    text-align: right;
    
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.article-author-menu {
    text-align: left;
    width: auto;
    padding: 0.5rem;
    font-size: 0.9em;
    
    a {
        display: block;
    }
}
.article-body {
    clear: both;
    
    p, ul {
        margin-bottom: 0.75rem;
        font-size: 1rem !important;
        line-height: 1.429 !important;
        text-align: left !important;
    }
    h2 {
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
    }
    h3 {
        font-size: 1.063rem;
        font-weight: bold;
    }
    h4 {
        font-size: 0.938rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }
    p~h2 {
        padding-top: 0.75rem;
    }
    p~h3 {
        padding-top: 0.5rem;
    }
    p~h4 {
        padding-top: 0.25rem;
    }
}
.article-shop {
    margin-bottom: 1.5rem;
}
.article-header-table {
    font-size: 0.9rem;
    margin-bottom: 2rem;
    
    tbody td, tbody th {
        padding: 0.2rem 0.5rem 0.25rem 1rem;
    }
}
.article-ht-value {
    font-weight: 600;
}
.article-gal {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    margin: 0 -11px;
    clear: both;
    
/*    .galleryimg {
        margin: 0 1rem 0.5rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }*/
    a, .empty-thumb {
        flex-grow: 1;
        flex-basis: 102px;
        max-width: 102px;
        margin: 10px;
        border: 1px solid transparent;
        line-height: 95px;
    }
    a {
        &.img-thumb {
            width: 102px;
            height: 102px;
        }
        >img {
            position: relative;
            height: auto;
            width: auto;
        }
    }
    .empty-thumb {
        margin: 0 10px;
    }
}

.page-getAdv {
    .article-header {
        margin-bottom: 0.5rem;
    }
    .article-date {
        margin-top: 0.25rem;
        width: auto;
    }
    .article-ht-value {
        width: 50%;
    }
    .article-main-img {
        margin-bottom: 1rem;
    }
}
.page-getArticle {
    .article-main-img-wrapper {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0.8rem 0;
        
        img {
            width: 100%;
        }
    }
}
@media screen and #{breakpoint(medium)} {
    .article {
        .article-main-img {
            float: left;
            margin: 0.3rem 1rem 0.5rem 0;
        }
    }
    .page-getAdv .article .article-main-img, .page-getOrg .article .article-main-img {
        margin-right: 2rem;
    }
    .page-getArticle {
        .article-main-img-wrapper {
            display: inline;
            width: auto;
            margin-bottom: 0;
            margin: 0;

            img {
                width: auto;
            }
        }
    }
}

@media screen and #{breakpoint(xlarge)} {
    .page-getAdv {
        .article-ht-value {
            width: 60%;
        }
    }
}