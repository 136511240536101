// переопределяем вид боксов для view=rows
.order.product-list.list-view-rows {
    .obj-box-wrapper-except-img {
        justify-content: flex-start;
    }
    .obj-box-order-info {
        width: 100%;
    }
    .list-header {
        .product-list-th-order-qnt {
            display: inline-block;
            text-align: center;
            width: 100px;
        }
        .product-list-th-order-sum {
            display: inline-block;
            text-align: center;
            width: 120px;
        }
    }
}
@media screen and #{breakpoint(medium)} {
    .order.product-list.list-view-rows {
/*        .obj-box-order-sum{
            font-size: 1rem;
            width: 90px;
        }
        .obj-box-order-qnt {
            width: 125px;            
        }*/

/*        .obj-box-order-qnt {
            width: 20%;
            align-self: center;
            text-align: center;
        }
        .obj-box-order-sum {
            width: 28%;
            align-self: center;
            margin-right: 5%;
        }
        .list-header {
            
            .product-list-th-order-name {
                display: inline-block;
                width: 51%;
            }
            .product-list-th-order-qnt {
                width: 21%;
            }
            .product-list-th-order-sum {
                width: 19%;
                margin-right: 0;
            }
        }*/
    }
}
/*@media screen and #{breakpoint(large)} {

}*/
@media screen and #{breakpoint(large)} {
    .order.product-list.list-view-rows {
        .obj-box-wrapper-except-img {
            justify-content: space-between;
        }
        .obj-box-order-info {
            width: 45%;
            padding-right: 2em;
            margin-bottom: 0;
        }
        .obj-box-order-qnt {
            width: 20%;
            align-self: center;
            text-align: center;
        }
        .obj-box-order-sum {
            width: 28%;
            align-self: center;
            text-align: center;
            margin-right: 5%;
        }
        
        .list-header {    
            display: block;
            padding-left: 81px;
            
/*            .product-list-th-cart-nameshort {
                display: none;
            }*/
            .product-list-th-order-name {
                display: inline-block;
                width: 45%;
            }
            .product-list-th-order-qnt {
                width: 21%;
            }
            .product-list-th-order-sum {
                width: 27%;
                margin-right: 5%;
            }
        }
    }
}