.l-filter {
    padding-bottom: 0.9rem;
    padding-top: 0.5rem;
}
.flt-box {
    font-size: 0.9rem;
}
.desktop {   
    .flt-box {
        border-top: 1px solid $colorBorder;
        padding-top: 0.8rem;    
        margin: 0.8rem 1rem 0;
    }
    .product-filter-panel {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
.flt-box.first {
    margin-top: 0;
    padding-top: 0rem;
    border-top: 0;
}
.flt-box-title {
    text-transform: uppercase;
    padding-bottom: 0.6rem;
    font-weight: bold;
    span,a {
        display: inline-block;
    }
    a.icon-question {
        margin-top: -3px;
        margin-left: 3px;
    }
}
.flt-box-body {
    ul {
        list-style-type: none;
        margin-left: 0;
        margin-bottom: 0;
        
        li {
            a.flt-l-a {
                font-size: 0.8rem;
                text-decoration: none;
                color: $colorContrast;
                padding-left: 24px;
                background: url(/img/icons.png) no-repeat -64px -2127px;

                &:hover {
                    text-decoration: underline;
                }
            }
            span {
                color: $colorRemark;
            }
            a.icon-question {
                margin-left: 3px;
            }
        }
        li.flt-active {
            a.flt-l-a {
                background-position: -83px -1616px;
            }
        }
    }
}
.mob-product-filter {
    display: none;
    position: absolute;
    top: 38px;
    left: 0;
    background-color: #fff;
    width: 265px;
    z-index: 9996;
    padding: 2.5rem 0.5rem 0.5rem;
    border-radius: 0 0 3px 3px;
    border: 1px solid $colorContrast;
}
.mob-product-filter-close {
    position: absolute;
    top: 0;
    right: -32px;
    background-color: #fff;
    padding: 5px 8px;
    border-radius: 0 3px 3px 0;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
}

.flt-reset-button a, .flt-apply-button a {
    font-size: 0.8rem;
    
    &:hover {
        text-decoration: none;
        color: $colorContrast;
    }
}
.flt-reset-button {
/*    text-align: right;
    margin-right: 1rem;*/
    margin-bottom: 0.5rem;
        
    a.btn-reset {
        width: 100%;
    }
    
    a {
        color: $colorActiveSupport; 
    }
}
.flt-apply-button {
    /*margin-left: 1.2rem;*/
    
    a {
        color: $colorPrimary;
    }
}

.filter-price-cont {
    label {
        padding-right: 0.3rem;
    }
    input.priceInput {
        font-size: 0.8rem;
        width: 72px;
        height: 1.5rem;
        margin: 0;
        padding: 0.2rem 0 0.2rem 0.3rem;
        display: inline-block;
    }
    .button {
        margin: 0;
        height: 1.5rem;
        padding: 0.1rem 0.3rem 0;
        font-size: 0.9rem;
    }
    .span {
        line-height: 1.8;
    }
    .shrink-fix {
        text-align: right;
        width: 24px !important;
    }
}
.slider {
    border: 1px solid $colorBorder;
    margin-bottom: 1.8rem;
}

.mobile { 
    .filter-price-cont {
        input.priceInput {
            width: 110px;
        }
    }
    .flt-box-body {
        border: 1px solid $colorBorder;
        padding: 0.5rem;
    }
    .product-filter-panel {
        position: fixed;
        width: 263px;
        background-color: #fff;
        z-index: 9998;
        top: 38px;
        left: 1px;
        padding: 0.5rem 0;
        border-bottom: 1px solid $colorBorder;
    }
    .flt-reset-button a, .flt-apply-button a {
        text-transform: uppercase;
        font-weight: bold;
        width: auto;
    }
    .flt-reset-button {
        text-align: left;
        margin: 0 0 0 0.4rem;
    }
    .flt-apply-button {
        margin: 0 0.5rem 0 0;
        text-align: right;
    }
}
@media screen and #{breakpoint(medium)} {
    .filter-price-cont {
        input.priceInput {
            width: 92px;
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .flt-box-body {
        ul {
            li {
                a.flt-l-a {
                    font-size: 0.9rem;
                }
            }
        }
    }
    .filter-price-cont {
        label {
            width: auto;
        }
        input.priceInput {
            font-size: 0.9rem;
            width: 100%;
        }
        .shrink-fix {
            text-align: left;
            width: auto !important;
        }
    }
}