.fdatepicker {
    /*position: relative;*/
}
.fdatepicker-icon {
    width: 24px;
    height: 22px;
    
    z-index: 99;
    position: absolute;
    top: 4px;
    right: 15px;
    background: url(/img/icons.png) no-repeat -77px -2010px;
}
.field.required .fdatepicker-icon {
    right: 33px;
}