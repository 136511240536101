#mob-disable-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 37px;
    left: 0;
    z-index: 9998;
    background: rgba(66, 65, 65, 0.5);
}
#mobile-menu-wrapper {
    background-color: #fff;
    border: 0;
    border-radius: 0 0 2px 2px;
    padding: 7px;
    width: 270px;
    z-index: 9999;
    top: 38px !important;
    left: 0 !important;
    
    .menu {
        a {
            position: relative;
            display: block;
            color: $colorContrast;
            border: 1px solid $colorBorder;
            padding: 0.4rem 0.5rem;
            font-size: 1rem;
            
            .icon-submenu {
                position: absolute;
                right: 5px;
            }
            &.item-g {
                color: $colorPrimary;
            }
            &.item-o {
                color: $colorActiveSupport;
            }
            &.drp-version-button {
                color: $colorContrast;
            }
        }
        a:hover, a.active, a:focus {
            outline: none;
        }
        a:hover {
            text-decoration: underline;
        }
        
        li {
            width: 100%;
            margin-bottom: 3px;
        }
        li::first-child {
            margin-bottom: 0;
        }
    }
    .mobile-menu-catalogue {
        .menu {
            a {
                padding: 0.25rem 0.5rem;
            }
            ul {
                margin-left: 0;
                list-style-type: none;
                margin-top: 3px;
                li {
                    margin-bottom: 0;
                }
                a {
                    padding-left: 1.5rem;
                    font-size: 0.813em;
                }
            }
        }
        .icon {
            display: none;
        }
    }
    .mobile-menu-close {
        position: absolute;
        top: 0px;
        right: -26px;
        background-color: #fff;
        width: 26px;
        height: 26px;
        text-align: center;
    }
} 
/*.page-home {
    #mobile-menu-wrapper {
        .mobile-menu-close {
            padding: 5px;
        }
    }
}*/
.mobile-menu-profileoptions {
/*    .menu a {
        
    }*/
    > ul>li {
        font-style: italic;

        ul {
            list-style-type: none;
            font-style: normal;
            margin-top: 0.5rem;
        }
    }
}