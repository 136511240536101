.input-number-group {
    display: flex;
    justify-content: center;

    .input-group-button {
        line-height: 1;//calc(#{$input-number-size}/2 - 5px);
    }

    .input-number {
        /*width: $input-number-size;*/
        /*padding: 0 12px;*/
        /*vertical-align: top;*/
        /*outline: none;*/
/*        display: block;
        margin: 0;*/
        margin: 0;
        height: 24px;
        padding: 0.2rem 0.5rem;
        text-align: center;
        width: 50px;
    }

    .input-number-decrement,
    .input-number-increment {
        border: 1px solid $colorBorder;
        width: 24px;
        height: 24px;//$input-number-size/2;
        
        display: inline-block;
        background-color: $colorBorder;
        color: $body-font-color;
        text-align: center;
        cursor: pointer;
        font-size: 2rem;
        
        .icon {
            vertical-align: top;
            margin-top: 2px;
        }
        &:hover {
            background-color: $colorPrimary;
            border: 1px solid $colorPrimary;
        }
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
}
input[type=number] {
    appearance: textfield;
    /*-moz-appearance: textfield;*/
}