a.select-emul {
    border-radius: 2px;
    border: 1px solid $colorBorder;
    color: $colorContrast;
    text-decoration: none;
    
    height: 1.6rem;
    padding: 0.1rem 1.5rem 0.2rem 0.5rem;
    width: auto;
    font-size: .8em;
    
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2866, 65, 65%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right -1rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    /*padding-right: 1.5rem;*/
    transition: box-shadow .5s,border-color .25s ease-in-out,-webkit-box-shadow .5s;
}
.select-emul-menu {
    padding: 0;
    box-shadow: 0 15px 25px rgba(66,66,66,.3);
    width: auto;
    /*margin-top: 2px;*/
    
    a {
        display: block;
        color: $colorContrast;
        text-decoration: none;
        padding: 1px 0.5rem;
        font-size: 0.9rem;
        font-style: italic;
        
        &.active {
            background-color: $colorBorder;
        }
    }
    a:hover {
        background-color: $colorBorder;
    }
}