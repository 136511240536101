html, body {
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
    background-color: $colorBg;
}
.wrapper {
    flex: 1 0 auto;
}
.main {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    
    .block-main.block {
        min-height: calc(100% - 3.2rem);
        margin-top: 2.5rem;
    }
}
/*.header {
    background-color: green;
}*/
.footer {
    background-color: $colorFooter;
    color: #fff;
/*    position: relative;
    margin-top: -515px;
    height: 515px;*/
}
#wrapper-main-menu {
    position: relative;
}

body.desktop {
    .wrapper, .footer {
        min-width: 753px;//768px;
    }
}

.container {
    @include xy-grid-container;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
}

.l-block {
    @include block;
    margin-bottom: $marginBlockDefault;
}
.l-block-transparent {
    margin-bottom: $marginBlockDefault;
    
    .button {
        width: 100%;
    }
}
.l-block-caption {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem 0;
    border-bottom: 1px solid $colorBorder;
    margin: 0 1rem 1rem;
}
.l-block-body {
    padding: 0 1rem 1rem;
    font-size: .8rem;
    line-height: 1.2;
}
.l-block-sep {
    border-top: 1px solid $colorBorder;
    padding-top: 0.5rem;
}

iframe {
    width: 100%;
    max-width: 595px;
}
iframe.cke_wysiwyg_frame {
    max-width: 100%;
}

.col-l {
    width: $widthColumnLeft;
    margin-right: $marginBlockDefault;
}

.b-side {
    text-align: center;
    width: 100%;
    background-color: #989898;
    overflow: hidden;
    border-radius: 2px;
    display: block;
}
.b-side-lite {
    background-color: #E8E8E8;
}

// =============================================================================
.mobile {
    .main {
        .block-main.block {
            height: auto !important;
            margin-top: 0;
        }
    }
    .seller-reviews {
        margin-top: 1rem;
    }
}
// =============================================================================

@media screen and #{breakpoint(xmedium)} {
    .col-l {
        margin-right: 0;
    }
    .desktop .col-r {
        padding-left: $marginBlockDefault;
    }
/*    .main {
        .block-main.block {
            margin-top: 2.5rem;
        }
    }*/
}
@media screen and #{breakpoint(medium)} {
    .container{
        //max-width: 48rem;//46.125rem; //задаем 738px
    }
    .main {
        margin-top: 0.675rem;
        margin-bottom: 1.25rem;
    }
}
@media screen and #{breakpoint(large)} {
    .main {
        margin-top: 1.25rem;
        
        .block-main.block {
            margin-top: 0;
            min-height: calc(100% - 1.2rem);
        }
    }
}
@media screen and #{breakpoint(xlarge)} {
    .col-l {
        width: $widthColumnLeft1200;
    }
    .desktop .col-r {
        /*width: $widthColumnLeft;*/
        padding-left: $marginBlock1200;
    }
    .container{
        max-width: 76.875rem; //задаем 1250px - общая ширина включая паддинги 10px указанные ниже
/*        padding-left: 0.625rem;
        padding-right: 0.625rem; //10px*/
        padding: 0;
    }
    .l-block, .l-block-transparent {
        margin-bottom: $marginBlock1200;
    }
/*    .main {
        margin-top: 1.25rem;
        
        .block-main.block {
            margin-top: 0;
            min-height: calc(100% - 1.2rem);
        }
    }*/
}
@media screen and #{breakpoint(xxxlarge)} {
    .col-l {
        width: $widthColumnLeft1600;
    }
    .container{
        max-width: 97.5rem; //1520px, чтобы получить container 1560px
/*        padding-left: 1.25rem;
        padding-right: 1.25rem; // 20px*/
    }
}