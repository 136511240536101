.switcher-view a {
    margin-left: 3px;
}
.product-list-switchers {
    padding-top: 0.2rem;
    
    .pager-form {
        float: right;
    }
    .switcher-sort label {
        display: none;
    }
}

.oldprice {
    color: #999999;
    text-decoration: line-through;
    font-size: 0.9em;
    font-weight: normal;
    white-space: nowrap;
}
.price, .price-label {
    font-weight: bold;
}
.price {
    white-space: nowrap;
}
.sh .price {
    white-space: normal;
}

@media screen and #{breakpoint(medium)} {
    .product-list-switchers {
        border-bottom: 1px solid $colorBorder;
        padding-bottom: 0.5rem;
    
        .switcher-sort label {
            display: inline;
        }
    }
}