.button-filter {
    position: relative;
    border: 1px solid $colorPrimary;
    font-size: 0.8rem;
    text-decoration: none;
    padding: 0.1rem 0.4rem 0.2rem;
    border-radius: 2px;
    margin-right: 3px;
    
    &:hover {
        border: 1px solid $colorActive;
        color: $colorActive;
    }
    &.filtered {
        width: 80px;
        display: block;
        text-align: center;
        line-height: 1;
        font-size: 0.76rem;
        padding-bottom: 0.1rem;
        color: $colorActive;
        border: 1px solid $colorActive;
    }
    &.is-filtered::after {
        content: '!';
        display: block;
        position: absolute;
        right: 2px;
        top: -11px;
        text-align: center;
        background-color: $colorActive;
        width: 16px;
        height: 18px;
        font-weight: bold;
        color: #fff;
    }
}

.mobile {
    .header-mob-breadcrubms {
        background-color: $colorPrimary;
        position: relative;
    }
    .breadcrumbs {
        margin: 0 0 0 1rem;
        /*padding: 40px 0 0.3rem 0.5rem;*/
        padding: 42px 32px .5rem .5rem;
        position: relative;
        
        a {
            color: #fff;
        }
        &::before {
            position: absolute;
            content: " ";
            background: url(/img/icons.png) no-repeat -78px -960px;
            width: 20px; height: 20px;
            left: -10px;
            top: 43px;
        }
    }
/*    .fancybox-show-thumbs .fancybox-thumbs {
        display: none;
    }*/
    .col-r {
        .l-block-transparent {
            margin: 0.5rem 0 0.2rem;
        }
    }
    
    &.sh {
        .mob-product-filter .menu a {
            padding: 0.1rem 0;
        }
    }
    
    .pager-top {
        margin-top: 0.5rem;
    }
}
.mobile-menu-profile-btn {
    position: absolute;
/*    top: 36px;
    right: 8px;*/
/*    bottom: 4px;
    right: 5px;
    height: 26px;*/
    bottom: 3px;
    right: 5px;
    height: 25px;
    border: 1px solid #fff;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    padding: 1px;
    
    .icon-profile-w {
        width: 22px;
        height: 14px;
        background-position: -8px -2105px;
        vertical-align: initial;
    }
}
#mobile-menu-profile-body {
    width: 200px;
    /*margin-top: 3px;*/
}