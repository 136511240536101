.icon {
    display: inline-block; 
    vertical-align: middle;
    width: 16px;
    height: 17px;
    background-image: url(/img/icons.png);
    background-repeat: no-repeat;
}
.icon-right {
    margin-left: 7px;
}
.icon-left {
    margin-right: 7px;
}
.floated-icon-left {
    float: left;
    margin-right: 10px;
}

.icon-phone {background-position: -9px -12px;}
.icon-skype {background-position: -81px -2152px;} //-56px -10px
.icon-ims {background-position: -9px -2151px; width: 45px;/*66px;*/ height: 20px;}
.icon-email {background-position: -87px -93px;}
.icon-submenu {background-position: -63px -885px;} //-70px -886px margin-left: 10px;
.icon-phone-wh {background-position: -32px -12px;}
.icon-skype-wh {background-position: -83px -10px;}

.icon-search {width: 19px; height: 19px; background-position: -33px -41px;}
.icon-search:hover {background-position: -7px -41px;}

.icon-reset-filter {background-position: -61px -70px; width: 13px; height: 12px;}
.icon-reset-filter:hover, a:hover .icon-reset-filter {background-position: -84px -70px;}
.icon-apply-filter {background-position: -85px -739px;}
.icon-apply-filter:hover, a:hover .icon-apply-filter {background-position: -85px -739px;}

.icon-del {background-position: -80px -69px;}
.icon-del:hover, a:hover .icon-del, .icon-del-active {background-position: -57px -69px;}

.icon-youtube {width: 63px; height: 26px; background-position: -15px -245px}
.icon-youtube-light {width: 63px; height: 26px; background-position: -15px -212px}
.icon-youtube-small {width: 25px; height: 21px; background-position: -15px -186px}
.icon-youtube-small:hover, .button:hover .icon-youtube-small {background-position: -51px -186px}

.icon-soc-fb {background-position: -10px -328px}
.icon-soc-bl-fb {background-position: -54px -423px}
.icon-soc-fb:hover, .icon-soc-bl-fb:hover, a:hover .icon-soc-fb {background-position: -58px -328px}
.icon-soc-vk {background-position: -10px -282px}
.icon-soc-bl-vk {background-position: -11px -423px}
.icon-soc-vk:hover, .icon-soc-bl-vk:hover, a:hover .icon-soc-vk {background-position: -58px -282px}
.icon-soc-in {background-position: -10px -375px}
.icon-soc-bl-in {background-position: -11px -470px}
.icon-soc-in:hover, .icon-soc-bl-in:hover, a:hover .icon-soc-in {background-position: -58px -375px}
.icon-soc-ok {background-position: -8px -1730px}
.icon-soc-bl-ok {background-position: -8px -1776px}
.icon-soc-ok:hover, .icon-soc-bl-ok:hover, a:hover .icon-soc-ok {background-position: -58px -1730px}

.icon-shevron-right {background-position: -85px -620px; width: 13px;}
.icon-shevron-left {background-position: -89px -644px; width: 13px;}

.icon-arr-right {width: 32px; height: 44px; background-position: -32px -1209px;}
.icon-arr-left {width: 32px; height: 44px; background-position: -10px -1209px;}
button:hover .icon-arr-right {width: 32px; height: 44px; background-position: -32px -1209px;} // need orange version
button:hover .icon-arr-left {width: 32px; height: 44px; background-position: -10px -1209px;}
.icon-arr-down {background-position: -70px -886px;}

.icon-expand-down {background-position: -43px -884px; margin-left: 8px;}
/*.icon-expand-up {background-position: -70px -886px;}*/
.icon-expand-down-sm {background-position: -88px -930px; width: 9px;}

.icon-arr-sm-l {background-position: -78px -960px;}
.icon-arr-sm-r {background-position: -79px -987px;}

.icon-close {width: 15px; height: 15px; background-position: -60px -42px;}
.icon-close-small {
    background-position: -65px -1593px; width: 8px; height: 8px;
}

.icon-info {width: 32px; height: 32px; background-position: -14px -1328px;}
.icon-info-email {width: 32px; height: 32px; background-position: -14px -1366px;}
.icon-info-skype {width: 32px; height: 32px; background-position: -14px -1405px;}
.icon-info-whatsapp {width: 32px; height: 32px; background-position: -14px -1442px;}
.icon-info-phone {width: 32px; height: 32px; background-position: -14px -1479px;}
.icon-info-telegram {width: 32px; height: 32px; background-position: -14px -1515px;}
.icon-info-viber {width: 32px; height: 32px; background-position: -14px -1553px;}

.icon-consult {width: 26px; height: 26px; background-position: -6px -1586px;}

.pseudo-table-row:hover .icon-info-email {background-position: -59px -1366px;}
.pseudo-table-row:hover .icon-info-skype {background-position: -59px -1405px;}
.pseudo-table-row:hover .icon-info-whatsapp {background-position: -59px -1442px;}
.pseudo-table-row:hover .icon-info-phone {background-position: -60px -1479px;}
.pseudo-table-row:hover .icon-info-telegram {background-position: -59px -1515px;}
.pseudo-table-row:hover .icon-info-viber {background-position: -59px -1553px;}

.icon-view-block {background-position: -45px -794px; width: 24px; height: 24px;}
.active .icon-view-block, .icon-view-block:hover {background-position: -10px -794px}
.icon-view-list {background-position: -45px -823px; width: 24px; height: 24px;}
.active .icon-view-list, .icon-view-list:hover {background-position: -10px -823px}
.icon-view-table, .icon-view-rows {background-position: -45px -852px; width: 24px; height: 24px;}
.active .icon-view-table, .icon-view-table:hover,
.active .icon-view-rows, .icon-view-rows:hover {background-position: -10px -852px}

.icon-menu {background-position: -49px -1693px; width: 24px; height: 24px;}

.icon-question {background-position: -9px -913px; width: 18px; height: 18px;}
a:hover .icon-question, .icon-question:hover {background-position: -38px -913px}

.icon-new {width: 39px; height: 23px; background-position: -10px -151px;}
.icon-hit {width: 39px; height: 23px; background-position: -55px -119px;}
.icon-sale {width: 39px; height: 23px; background-position: -55px -151px;}
.icon-video {width: 26px; height: 23px; background-position: -55px -89px;}
/*.icon-search:hover {background-position: -7px -41px;}*/

/*.icon-cart {
    display: inline-block;
    width: 45px;
    height: 36px;
    background: url(/img/icons.png) no-repeat -5px -73px;
}*/

.icon-payment {
    width: 87px; height: 61px;
    background-position: -12px -1021px;
}
.icon-delivery {
    width: 87px; height: 61px;
    background-position: -10px -1086px;
}
.icon-returns {
    width: 87px; height: 71px;
    background-position: -14px -950px;
}

.icon-dlv {
    display: inline-block; 
    vertical-align: middle;
    width: 60px;
    height: 40px;
    background-image: url(/img/delivery_directions.png);
    background-repeat: no-repeat;
    margin-right: 7px;
}
.icon-dlv-ukr {background-position: -12px -15px;}
a:hover .icon-dlv-ukr {background-position: -80px -15px;}
.icon-dlv-rus {background-position: -12px -173px;}
a:hover .icon-dlv-rus {background-position: -80px -173px;}
.icon-dlv-bel {background-position: -12px -65px;}
a:hover .icon-dlv-bel {background-position: -80px -65px;}
.icon-dlv-kaz {background-position: -12px -118px;}
a:hover .icon-dlv-kaz {background-position: -80px -118px;}
.icon-dlv-pmr {background-position: -12px -229px;}
a:hover .icon-dlv-pmr {background-position: -80px -229px;}
.icon-dlv-other {background-position: -12px -335px;}
a:hover .icon-dlv-other {background-position: -80px -335px;}
.icon-dlv-kh {background-position: -12px -281px;}
a:hover .icon-dlv-kh{background-position: -80px -281px;}

.icon-dlv-way-curier {width: 32px; height: 32px; background-position: -4px -1614px;}
.icon-dlv-way-self {width: 32px; height: 32px; background-position: -37px -1614px;}
.icon-dlv-way-np {width: 32px; height: 32px; background-position: -71px -1649px;}
.icon-dlv-way-ukrpost {width: 32px; height: 32px; background-position: -37px -1649px;}
.icon-dlv-way-ps {width: 32px; height: 32px; background-position: -4px -1649px;}

.icon-comment {width: 26px; height: 26px; background-position: -46px -1829px; margin-right: 2px;}
.icon-trn-open {background-position: -29px -1861px;}
.icon-trn-close {background-position: -58px -1859px;}
.icon-trn-inner {background-position: -3px -1861px;}
.icon-trn-official {background-position: -77px -1828px;}
.icon-trn-raiting {background-position: -81px -1858px;}

.icon-edit {width: 26px; height: 26px; background-position: -63px -2099px;}
.icon-profile {width: 26px; height: 14px; background-position: -80px -2054px;}
a:hover .icon-profile {background-position: -80px -2076px;}
.icon-profile-o {width: 26px; height: 19px; background-position: -29px -2104px;}
.icon-profile-w {width: 22px; height: 13px; background-position: -8px -2105px;}

.icon-article {width: 28px; height: 28px; background-position: -5px -1893px;}
.icon-club {width: 28px; height: 28px; background-position: -41px -1893px;}
.icon-tournament {width: 28px; height: 28px; background-position: -73px -1893px;}
.icon-couch {width: 28px; height: 28px; background-position: -6px -1931px;}
.icon-trainer {width: 36px; height: 28px; background-position: -41px -1931px;}
.icon-sparring {width: 21px; height: 28px; background-position: -80px -1931px;}

.icon-view-package {
    background-image: none;
    border: 6px solid #E9E9E9;
    height: 24px;
    width: 24px;
}
a.active .icon-view-package, a:hover .icon-view-package {
    border: 6px solid #66ac4a;
}