// цвета для характеристика выбора товара Цвет
.color-white {background-color: #fff}
.color-yellow {background-color: #ffed39}
.color-cream {background-color: #fee998}
.color-orange {background-color: #f8901f}
.color-red {background-color: #ed1c24}
.color-rose {background-color: #ffa6ba}
.color-green {background-color: #3f7e33}
.color-light-green {background-color: #c8fe2e}
.color-blue {background-color: #74bce8}
.color-navy {background-color: #2323ee}
.color-violet {background-color: #713c96}
.color-brown {background-color: #6f472d}
.color-gray {background-color: #8a8e98}
.color-silver {background-color: #b9c1c4}
.color-black {background-color: #000000}
.color-bordo {background-color: #96251d}
.color-magenta {background-color: #e31486}
.color-gold {background-color: #bd9028}
.color-lilac {background-color: #C8A2C8}
.color-differ {background: url(/img/color-differ.png) no-repeat 50% 50%;}