.hint-popup {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 300px;
    background-color: $colorContrast;
    color: #fff;
    border-radius: 2px;
    padding: 1rem 0.7rem 0.5rem;
    font-size: 0.813rem;
    z-index: 99;
}
.field .hint-popup {
    right: 9px;
    top: 30px;
}
.hint-popup-close {
    position: absolute;
    display: block;
    top: 5px;
    right: 5px;
    width: 8px;
    height: 8px;
}